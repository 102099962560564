import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import HeaderLogo from '../../assets/my-store-logo-1.png';
import FooterLogo from '../../assets/prosperna-footer-logo.png';
import ThankYouLogo from '../../assets/thank-you-create-icon.png';
import { Redirect } from 'react-router-dom';
import {
    useParams
} from "react-router-dom";
import axios from 'axios';
import { getAuthorizationToken } from '../../redux/actions/UserActions';

const baseURL = process.env.REACT_APP_API_URL;

function ThankYou() {
    const userData = useSelector(state => state.user.user_details);
    const [isLoading, setLoading] = useState(false);
    const [goToDashboard, setGoToDashboard] = useState(false);

    const goToSite = async () => {
        setLoading(true);
        const url = await Promise.resolve(getDomainName());
        window.open(url, '_blank');
        setLoading(false);
        setGoToDashboard(true);
    }

    let { sub_id } = useParams();
    const getDomainName = (arrayURL) => {
        const authToken = getAuthorizationToken(userData.session_token, userData.user_id);
		return axios
		.get(baseURL+'/api/user/domain/get/'+sub_id+'/'+userData.user_id, 
		{
			headers: {'Content-Type':'application/json', Authorization: authToken}
		})
		.then(response => {
            return "https://"+response.data.domain+".prosperna.ph/admin";
		});
    }
    
    if(goToDashboard){
        return <Redirect to='/dashboard' />
    }
    return (
        <section id="__request-finished">
            <div className="container">
                <div className="row __header">
                    <img src={HeaderLogo} alt="my-store-logo-1"/>
                </div>
                <div className="row __body pb-4">
                    <div className="__message-wrap">
                        <div className="mb-4">
                            <img src={ThankYouLogo} alt="ThankYouLogo"/>
                        </div>
                        <div>
                            <h1>Thank you!</h1>
                            <p><b>Your online store is now ready for you to personalize.</b></p>
                            <p>Login to configure your branding, upload your logo, manage products and pricing so that <br/>
    you can start your online business.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="__button-wrap">
                        <button className="btn btn-primary" onClick={() => {goToSite()}} style={{width: "357px"}} disabled={isLoading}>{isLoading ? <Spinner animation="border" /> : 'COMPLETE YOUR ONLINE STORE SETUP'}</button>
                    </div>
                </div>
                <div className="row __footer">
                    <p>Powered by <img src={FooterLogo} alt="prosperna-footer-logo"/></p>
                </div>
            </div>
        </section>
    )
}

export default ThankYou
