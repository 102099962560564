import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

export const getUserSubscriptions = (user_id, authToken) => {
    return axios
    .get(baseURL+`/api/stripe/subscription/listing/${user_id}`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: authToken }
        }
    )
    .then(function(response) {
        return {
            success: true,
            data: response.data
        }
    })
    .catch(error => {
        return {
            success: false,
            data: error
        }
    })
}

export const getUserSubscriptionsNextPage = (url, authToken) => {
    return axios
    .get(url,
        {
            headers: { 'Content-Type': 'application/json', Authorization: authToken }
        }
    )
    .then(function(response) {
        return {
            success: true,
            fetched_data: response.data
        }
    })
    .catch(error => {
        return {
            success: false,
            fetched_data: error
        }
    })
}