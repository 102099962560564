import React from 'react'
import '../../styles/BuildWebsite.scss'

function BuildWebsiteStepper(props) {
    const currentStep = props.currentStep;

    return (
        <React.Fragment>
            <section id="__step-description">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            {
                                currentStep === 1 ?
                                <React.Fragment>
                                    <h4>Select Your Industry</h4>
                                    <p>Tell us about your business so we can personalize your experience.</p>
                                </React.Fragment>
                                :
                                currentStep === 2 ?
                                <React.Fragment>
                                    <h4>Select Your Theme</h4>
                                    <p>Make your brand shine by selecting the online store theme of your choice.</p>
                                </React.Fragment>
                                :
                                currentStep === 3 ?
                                <React.Fragment>
                                    <h4>Select Your Plan</h4>
                                    <p>Choose from the list of services that best fits your needs.</p>
                                </React.Fragment>
                                :
                                currentStep === 4 ?
                                    <React.Fragment>
                                        <h4>Select Your Add-Ons</h4>
                                        <p>Choose from the list of additional services that best fits your needs</p>
                                    </React.Fragment>
                                :
                                currentStep === 5 ?
                                <React.Fragment>
                                    <h4>Checkout</h4>
                                    <p>This is the last step! Upon completion, your online store will be <br/> automatically created and ready for personalizing.</p>
                                </React.Fragment>
                                :
                                null
                            }
                            
                        </div>
                    </div>
                </div>
            </section>
            <section id="__step-indicator">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="steps-form">
                                <div className="step">
                                    <a href="#step" type="button" className={`btn btn-circle ${currentStep === 1 ? 'active': currentStep > 1 ? 'completed' : 'inactive'}`}>
                                        {currentStep > 1 ? <i className="fas fa-check"></i> : currentStep === 1 ? currentStep : 1}
                                    </a>
                                    <p>INDUSTRY</p>
                                </div>
                                <div className="step">
                                    <a href="#step" type="button" className={`btn btn-circle ${currentStep === 2 ? 'active': currentStep > 2 ? 'completed' : 'inactive'}`}>
                                        {currentStep > 2 ? <i className="fas fa-check"></i> : currentStep === 2 ? currentStep : 2}
                                    </a>
                                    <p>THEMES</p>
                                </div>
                                <div className="step">
                                    <a href="#step" type="button" className={`btn btn-circle ${currentStep === 3 ? 'active': currentStep > 3 ? 'completed' : 'inactive'}`}>
                                        {currentStep > 3 ? <i className="fas fa-check"></i> : currentStep === 3 ? currentStep : 3}
                                    </a>
                                    <p>PLANS</p>
                                </div>
                                <div className="step">
                                    <a href="#step" type="button" className={`btn btn-circle ${currentStep === 4 ? 'active': currentStep > 4 ? 'completed' : 'inactive'}`}>
                                        {currentStep > 4 ? <i className="fas fa-check"></i> : currentStep === 4 ? currentStep : 4}
                                    </a>
                                    <p>ADD-ONS</p>
                                </div>
                                <div className="step">
                                    <a href="#step" type="button" className={`btn btn-circle ${currentStep === 5 ? 'active': currentStep > 5 ? 'completed' : 'inactive'}`}>
                                        {currentStep > 5 ? <i className="fas fa-check"></i> : currentStep === 5 ? currentStep : 5}
                                    </a>
                                    <p>CHECKOUT</p>
                                </div>
                                <div className="step">
                                    <a href="#step" type="button" className="btn btn-circle inactive">6</a>
                                    <p>THANK YOU</p>
                                </div>
                                {/* <div className="step">
                                    <a href="#step" type="button" className="btn btn-circle inactive">6</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default BuildWebsiteStepper
