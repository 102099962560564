import React, { useEffect, useState } from 'react'
import Footer from '../Dashboard/Footer'
import Header from '../Dashboard/Header'
import BuildWebsiteStepper from './BuildWebsiteStepper'
import '../../styles/BuildWebsite.scss'
import {
    useParams
} from "react-router-dom";
import SelectIndustry from './SelectIndustry'
import SelectTheme from './SelectTheme'
import ChoosePlan from './ChoosePlan'
import Checkout from './Checkout'
import SelectAddons from './SelectAddons'
import $ from 'jquery';

function BuildWebsiteBody() {
    let { id } = useParams();
    // const [isScrollBottomMax, setScrollBottomMax] = useState(initialState);

    useEffect(() => {
        // alert($(document).height() - 30)
        // $(window).scroll(function() {
        //     let isBottomReached = false;
        //     try {
                
        //         if(($(window).scrollTop() + $(window).height()) == ($(document).height()) && !isBottomReached) {
        //             // if(isBottomReached == false){
        //              // alert(`${($(window).scrollTop() + $(window).height())} - ${($(document).height())}`)
        //                 if($("#__action-wrap").attr('class') == "fixed-footer"){
        //                     $("#__action-wrap").removeClass("fixed-footer");
        //                     $("#__home-footer").show();
        //                     $("#__home-footer").addClass("fixed-footer");
        //                     isBottomReached = true;
        //                 }else{
        //                     $("#__home-footer").show();
        //                     $("#__home-footer").addClass("fixed-footer");
        //                     isBottomReached = true;
        //                 }
        //             // }
        //         }
        //         else{
        //             isBottomReached = false;
        //             if($("#__home-footer").attr('class') == "fixed-footer" && $("#__action-wrap") != null){
        //                 $("#__action-wrap").addClass("fixed-footer");
        //                 $("#__home-footer").removeClass("fixed-footer");
        //                 $("#__home-footer").hide();
        //             } 
        //         }
        //     }catch(ex){

        //     }
        // });
    }, [])

    const currentStep = () => {
        $("#__home-footer").addClass("fixed-footer");
        $("#__action-wrap").addClass("fixed-footer");
        if(id === "industry"){
            return 1;
        }else if(id === "themes"){
            return 2;
        }else if(id === "plans"){
            return 3;
        }else if(id === "add-ons"){
            return 4;
        }else if(id === "checkout"){
            return 5;
        }
    };
    return (
        <React.Fragment>
            <Header />
            <BuildWebsiteStepper currentStep={currentStep()}/>
            {
                currentStep() === 1 ?
                    <SelectIndustry />
                :
                currentStep() === 2 ?
                    <SelectTheme />
                :
                currentStep() === 3 ?
                    <ChoosePlan />
                :
                currentStep() === 4 ?
                    <SelectAddons />
                :
                currentStep() === 5 ?
                    <Checkout />
                :
                    null
            }
            <Footer />
        </React.Fragment>
    )
}

export default BuildWebsiteBody
