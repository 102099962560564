import React, {useState} from 'react';
import HeaderLogo from '../../assets/my-store-logo-1.png';
import FooterLogo from '../../assets/prosperna-footer-logo.png';
import { Redirect, useLocation } from 'react-router-dom';

function EnterVerificationCode() {
    let location = useLocation();
    const [enteredCode, setEnteredCode] = useState("");
    const [goNextPage, setGoNextPage] = useState(false);

    const handleInputChange = (e) => {
        setEnteredCode(e.target.value);
    }

    const handleCodeVerification = (e) => {
        // Check if verification code is valid
        if(enteredCode != ""){
            if(location.state.verificationCode == enteredCode){
                setGoNextPage(true);
            }else{
                alert("You've entered an invalid code.");
            }
        }else{
            alert("Please enter your verification code.");
        }
    }

    if(goNextPage){
        return <Redirect 
            to={{
                pathname: "/forgot-password/enter-new-password",
                state: {
                    emailAddress: location.state.emailAddress
                }
            }}
        />
    }
    return (
        <section id="__request-finished">
            <div className="container">
                <div className="row __header">
                    <img src={HeaderLogo} alt="my-store-logo-1"/>
                </div>
                <div className="row __body mb-3">
                    <div className="__message-wrap">
                        <h1>Verification</h1>
                        <p>We just sent a verification code to: {location.state.emailAddress} <br/>
Please check your inbox and enter the code below.</p>
                    </div>
                </div>
                <div className="row __input-field mb-3">
                    <div className="col-lg-12 text-center">
                        <div className="mb-3">
                            <input type="text" placeholder="Enter Code" onChange={handleInputChange.bind(this)} defaultValue={enteredCode}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="__button-wrap mb-4">
                            <button className="btn btn-primary" onClick={handleCodeVerification.bind(this)}>SUBMIT</button>
                        </div>
                        <div>
                            <label className="text-muted text-center">Didn't receive the email? <a href="#resend">Resend Code</a></label>
                        </div>
                    </div>
                </div>
                <div className="row __footer">
                    <p>Powered by <img src={FooterLogo} alt="prosperna-footer-logo"/></p>
                </div>
            </div>
        </section>
    )
}

export default EnterVerificationCode
