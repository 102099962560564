import React from 'react'
import FooterLogo from '../../assets/prosperna-footer-logo.png'

function Footer() {
    return (
        <React.Fragment>
            <section id="__home-footer" className="fixed-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <p>Powered by <img src={FooterLogo} alt="prosperna-footer-logo"/></p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="__hidden-footer">

            </section>
        </React.Fragment>
    )
}

export default Footer
