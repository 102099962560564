import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import '../../styles/BuildWebsite.scss';
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { dispatchCartData } from '../../redux/actions/CartActions'

function SelectIndustry() {
    const dispatch = useDispatch();
    let cartData =  useSelector(state => state.cart.cart);

    useEffect(() => {
        document.title = "Select Industry | MyStore";
        cartData = {
            ...cartData,
            currentPathName: (window.location.pathname)
        }
        dispatch(dispatchCartData(cartData));
    }, [])

    const [goToThemes, setGoToThemes] = useState(false);
    const [goToDashboard, setGoToDashboard] = useState(false);
    const [currentIndustry, setCurrentIndustry] = useState(cartData.industry);

    const handleIndustryOnChange = (e) => {
        const { value } = e.target;
        setCurrentIndustry(value);
        cartData = {
            ...cartData,
            industry: value
        }
        dispatch(dispatchCartData(cartData));
    }

    const handleContinue = () => {
        if(cartData.industry === ""){
            alert("Please select an industry!");
        }else{
            setGoToThemes(true);
        }
    }
    
    if(goToThemes){
        return <Redirect to={{
            pathname: '/build-website/themes',

        }} />
    }else if(goToDashboard){
        return <Redirect to='/dashboard' />
    }
    return (
        <React.Fragment>
            <section id="__industry-select">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Form>
                                <Form.Group controlId="exampleForm.SelectCustomSizeLg" className="picklist">
                                    <Form.Control as="select" size="lg" custom onChange={handleIndustryOnChange.bind(this)} defaultValue={currentIndustry}>
                                        <option value={""}>Select Industry</option>
                                        <option value={"Art & Photography"}>Art & Photography</option>
                                        <option value={"Clothing & Fashion"}>Clothing & Fashion</option>
                                        <option value={"Electronics"}>Electronics</option>
                                        <option value={"Food & Beverage"}>Food & Beverage</option>
                                        <option value={"Furniture"}>Furniture</option>
                                        <option value={"Health & Beauty"}>Health & Beauty</option>
                                        <option value={"Home & Garden"}>Home & Garden</option>
                                        <option value={"Jewelry & Accessories"}>Jewelry & Accessories</option>
                                        <option value={"Professional Services"}>Professional Services</option>
                                        <option value={"Real Estate"}>Real Estate</option>
                                        <option value={"Sports & Recreation"}>Sports & Recreation</option>
                                        <option value={"Technology"}>Technology</option>
                                        <option value={"Toys & Games"}>Toys & Games</option>
                                        <option value={"Other"}>Other</option>
                                    </Form.Control>
                                    <i className="fa fa-chevron-down"></i>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>
            <section id="__action-wrap" className="">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-right">
                            <button className="btn btn-muted" onClick={() => {setGoToDashboard(true)}}>BACK</button>
                        </div>
                        <div className="col-lg-6 text-left">
                            <button className="btn btn-primary" onClick={() => {handleContinue()}}>CONTINUE</button>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default SelectIndustry
