import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

export const createCustomerPaymentMethod = (stripe, elements, user_id, authToken, result) => {
    return axios
    .post(baseURL+'/api/customer/create/'+user_id, 
    {
        email: result.paymentMethod.billing_details.email,
        payment_method: result.paymentMethod.id
    },{
        headers: {'Content-Type':'application/json', Authorization: authToken}
    })
    .then(response => {
        return {
            status: response.data.status,
            pm_id: response.data.result.id
        }
    })
    .catch(response => {
        return {
            status: false,
            data: response
        }
    });
}

export const createCustomerSubscription = (cartData, pm_id, user_id, authKey) => {
    // let pickedAddonsIds = []
    // cartData.pickedAddons.forEach((item, index) => {
    //     pickedAddonsIds.push(item.id)
    // });
    // console.log('pickedAddonsIdspickedAddonsIdspickedAddonsIds',pickedAddonsIds)
    const {plan_id, promo_code, picked_seo_types, setup_type} = cartData;
    let seo_type1 = '', seo_type = '';
    try{
        seo_type1 = picked_seo_types[0].type
    }catch(ex){
        seo_type1 = ''
    }
    try{
        seo_type = picked_seo_types[1].type
    }catch(ex){
        seo_type = ''
    }
    return axios
    .post(baseURL+'/api/subscription/create/'+user_id, 
    {
        customer_id: pm_id,
        plan_id: plan_id,
        promo_code_id: promo_code.id,
        setup_type: setup_type,
        seo_type1: seo_type1 ,
        seo_type: seo_type,
        picked_addons: JSON.stringify(cartData.pickedAddons) 

    },{
        headers: {'Content-Type':'application/json', Authorization: authKey}
    })
    .then(response => {
        if(response.data.status){
            return {
                status: true,
                data: response.data.result
            }
        }
        else{
            return {
                status: false,
                data: response.data.message
            }
        }
    })
    .catch(response => {
        return {
            status: false,
            data: response
        }
    });
}

export const createWebsite = (user_id, authToken, subscription_id) => {
    return axios
    .post(baseURL+'/api/website/create/'+user_id, 
    {
        id: subscription_id
    },{
        headers: {'Content-Type':'application/json', Authorization: authToken}
    })
    .then(response => {
        // alert(response.data);
        if(response.data.status){
            return {
                success: true,
                url: response.data.url
            }
            // amplitude.getInstance().init(process.env.REACT_APP_APMPLITUDE_KEY);
            // amplitude.getInstance().logEvent("USER: WEBSITE PAYMENT SUCCESS", {
            //     email: this.props.user.email_address,
            //     product_id: this.props.data.product_id,
            //     product_name: this.props.data.product_name,
            // }, () => {
            //     window.location.replace(response.data.url);
            // });
        }
        else{
            return {
                success: false,
                message: response.data.message
            }
            // this.setState({
            //     isPaymentLoading: false,
            //     isStripeError: true,
            //     stripeErrMsg: response.data.message
            // })
        }
    })
    .catch(response => {
        return {
            success: false,
            message: 'Payment succeed. An error occured while building your website.'
        }
        // this.setState({
        //     isPaymentLoading: false,
        //     isStripeError: true,
        //     stripeErrMsg: 'Payment succeed. An error occured while building your website.'
        // })
    });
}