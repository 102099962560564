import React, { useState } from 'react'
import { Form, Col, Button, InputGroup, Spinner, Image } from 'react-bootstrap';
import { useHistory, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { dispatchUserData, verifyCredentials } from '../../redux/actions/UserActions';
import { Link } from "react-router-dom";
import emailIcon from '../../assets/email-icon.png';
import lockIcon from '../../assets/lock-icon.png';
import failedCheck from '../../assets/failed-check.png'

function LoginForm() {
    // const userLogin = userLogin(email, password);
    const userDetails = useSelector(state => state.user)
    const dispatch = useDispatch();
    let history = useHistory();

    const [validated, setValidated] = useState(false);
    const [goToDashboard, setGoToDashboard] = useState(false);
    const [goToSignUp, setGoToSignUp] = useState(false);
    const [isShowPass, setIsShowPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userData, updateUserData] = useState({
        email_address: "",
        password: "",
    });
    const [loginErrorBool, setLoginResponse] = useState(false);

    const handleLoginUser = async () => {
        setIsLoading(true);
        const userRespData = await Promise.resolve(
            verifyCredentials(userData.email_address, userData.password)
        );
        const { success, data } = userRespData;
        
        if(success){
            data.currency_sym = data.currency === "1" ? "$" : data.currency === "2" ? "₱" : "";
            dispatch(dispatchUserData(data));
            history.push("/login")
            setGoToDashboard(true)
            setLoginResponse(false)
        }else{
            setLoginResponse(true)
            setIsLoading(false)
            document.getElementById('loginFeedback2').innerHTML = data;
        }
    } 

    const handleInputOnChange = (e) => {
        updateUserData(prevUserData => {
            return {
                ...prevUserData, 
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        }else{
            event.preventDefault();
            handleLoginUser()
        }
        
    };

    const handleGoToSignup = (e) => {
        e.preventDefault();
        history.push("/");
        setGoToSignUp(true)
    }
    
    
    if(goToDashboard) {
        if(localStorage.getItem("loginRedirectURL") != "null" && localStorage.getItem("loginRedirectURL") != null){
            return <Redirect to={{
                pathname: localStorage.getItem("loginRedirectURL")
            }}/>
        }else{
            return <Redirect to={{
                pathname: '/dashboard'
            }}/>
        }
    } else if (goToSignUp) {
        return <Redirect to="/" />
    }
    return (
        <div className="__login-form ">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                        <img src={emailIcon} alt="email icon"/>
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            name="email_address"
                            type="text" 
                            required 
                            onChange={handleInputOnChange.bind(this)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid Email Address.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                        <img src={lockIcon} alt="email icon"/>
                        <Form.Label>Password</Form.Label>
                        <InputGroup >
                            <Form.Control 
                                name="password"
                                type={isShowPass ? "text" : "password"}
                                aria-describedby="inputGroupPrepend" 
                                required 
                                onChange={handleInputOnChange.bind(this)}
                            />
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend"><span onClick={() => setIsShowPass(!isShowPass)}>{isShowPass ? "Hide" : "Show"}</span></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control.Feedback type="invalid" >
                                Please provide a valid Password.
                            </Form.Control.Feedback>
                            <div className="__forgot-password container-fluid">
                                <div className="row">
                                    <div className="col-m-12 mt-2">
                                        <Link className='text-muted' to="/forgot-password/enter-email-address" style={{cursor: "pointer"}}>
                                            Forgot Password ?
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </InputGroup>
                        <Form.Group>
                            <Form.Label className="mt-4">
                                <img src={failedCheck} style={{display: loginErrorBool ? '' : 'none' }}/>
                                <span id="loginFeedback2"></span>
                            </Form.Label>
                        </Form.Group>
                    </Form.Group>
                </Form.Row>
                <Form.Row className="__submit-btn-wrap justify-content-center d-flex">
                    <Button type="submit">{isLoading ? <Spinner animation="border" /> : 'Login'}</Button>
                </Form.Row>
                <Form.Row className='__register-container container justify-content-center d-flex mt-5'>
                    <label className="text-muted">Don’t have an account yet? <a href="#sign-up" onClick={handleGoToSignup.bind(this)} >Register</a></label>
                </Form.Row>
            </Form>
        </div>
    )
}

export default LoginForm
