import React, {useState} from 'react'
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import HeaderLogo from '../../assets/my-store-logo-1.png'
import QuestionEllipse from '../../assets/dashboard-question-ellipse.png'
import ShoppingCartLogo from '../../assets/dashboard-shopping-cart.png'
import AvatarLogo from '../../assets/dashboard-avatar.png'
import { Link, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Avatar from 'react-avatar';

function Header() {
    const [goToDashboard, setGoToDashboard] = useState(false);
    const [goToBuildWebsite, setGoToBuildWebsite] = useState(false);
    const [goToLogin, setGoToLogin] = useState(false);
    const cartData = useSelector(state => state.cart.cart);
    const userData = useSelector(state => state.user.user_details);
    
    const handleGoToDashboard = (e) => {
        e.preventDefault();
        setGoToDashboard(true)
    }

    const handleLogout = (e) => {
        e.preventDefault();
        localStorage.clear();
        window.location.replace('/login');
    }

    if(goToDashboard && window.location.pathname !== '/dashboard'){
        return <Redirect to='/dashboard' />
    }else if(goToLogin){
        return <Redirect to='/login' />
    }else if(goToBuildWebsite && window.location.pathname !== cartData.currentPathName){
        return <Redirect to={cartData.currentPathName} />
    }
    return (
        <React.Fragment>
            <section id="__home-dashboard">
                <Container className="container-fluid-md container-fluid-sm">
                    <Navbar collapseOnSelect expand="lg" bg="light" variant="light" sticky="top">
                        <Navbar.Brand href="#home"><img src={HeaderLogo} alt="my-store-logo-1"/></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link href="#dashboard" className="__dashboard-btn" onClick={handleGoToDashboard.bind(this)}>Dashboard</Nav.Link>
                                <Nav.Link href="https://help.prosperna.com/" target="_blank"><img src={QuestionEllipse} alt="QuestionEllipse" /></Nav.Link>
                                <Nav.Link><img src={ShoppingCartLogo} alt="ShoppingCartLogo" onClick={(e) => {e.preventDefault(); setGoToBuildWebsite(true)}}/></Nav.Link>
                                <NavDropdown title={<Avatar name={`${userData.first_name} ${userData.last_name}`} size="30" round={true}/>} id="collasible-nav-dropdown">
                                    <Link to={"/my-account"} className="dropdown-item">My Account</Link>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="/logout" onClick={(e) => {handleLogout(e)}}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </section>
            <section id="__hidden-navbar"></section>
        </React.Fragment>
    )
}

export default Header
