import {
    CART_UPDATE
} from './Types';

import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

export const dispatchCartData = (data) => {
    return {
        type:    CART_UPDATE,
        payload: data
    }
}

export const getPromoCodes = (user_id, authToken) => {
    return axios
    .get(baseURL+'/api/user/promo-codes/get/'+user_id, 
    {
        headers: {'Content-Type':'application/json', Authorization: authToken}
    })
    .then(function(response) {
        return {
            success: true,
            fetched_data: response.data
        }
    })
    .catch(error => {
        return {
            success: false,
            fetched_data: error
        }
    });
}

export const getAllAddons = (user_id, authToken) => {
    return axios
    .get(baseURL+'/api/all-addons/'+user_id, 
    {
        headers: {'Content-Type':'application/json', Authorization: authToken}
    })
    .then(response => {
        return {
            success: true,
            fetched_data: response.data
        }
    })
    .catch(response => {
        return {
            success: false,
            fetched_data: response
        }
    });
}

export const getThemeListing = (user_id, currency_id, authToken) => {
    return axios
    .get(baseURL + '/api/products/'+user_id+'/'+currency_id, 
        { 
            headers: { 'Content-Type': 'application/json', Authorization: authToken } 
        }
    ).then(function(response) {
        return {
            success: true,
            fetched_data: response.data
        }
    })
    .catch(error => {
        return {
            success: false,
            fetched_data: error
        }
    });
}

export const getThemeLisingPage = (url, authToken) => {
    return axios
    .get(url, 
        { 
            headers: { 'Content-Type': 'application/json', Authorization: authToken } 
        }
    ).then(function(response) {
        return {
            success: true,
            fetched_data: response.data
        }
    })
    .catch(error => {
        return {
            success: false,
            fetched_data: error
        }
    });
}

export const getAdditionalServices = (user_id, authKey) => {
    return axios
    .get(baseURL+'/api/additional-services/get/'+user_id, 
    {
        headers: {'Content-Type':'application/json', Authorization: authKey}
    })
    .then(response => {
        return {
            as_success: true,
            as_fetched_data: response.data
        }
        // if(response.data.status){
        //     this.setState({
        //         setupTypes: response.data.setup_types,
        //         seoTypes: response.data.seo_types,
        //     },() => {
        //         this.setState({
        //             renderDemoCheckoutModal: true,
        //             showCheckoutModal: !this.state.showCheckoutModal
        //         })
        //     })
        // }
    })
    .catch(response => {
        return {
            as_success: false,
            as_fetched_data: response
        }
    });
}

export const getPlansTypes = (product_id) => {
    return axios
    .get(baseURL+'/api/product/plan-types/get/'+product_id,
    {
        headers: {'Content-Type':'application/json'}
    })
    .then(response => {
        return {
            success: true,
            fetched_data: response.data
        }
    })
    .catch(error => {
        return {
            success: false,
            fetched_data: error
        }
    });
}