import './App.css';
import '../src/styles/Global.scss';
import SignUpBody from './components/SignUp/SignUpBody';
import SignUpThankYou from './components/SignUp/SignUpThankYou';
import LoginBody from './components/Login/LoginBody';
import Home from './components/Dashboard/Home';
import VerifyEmail from './components/Dashboard/VerifyEmail';
import "../src/assets/fonts/visbyroundcf/stylesheet.css";
import "../src/assets/fonts/roboto/stylesheet.css";
import "../src/assets/fonts/raleway/stylesheet.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import BuildWebsiteBody from './components/BuildWebiste/BuildWebsiteBody';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { store, persistor } from './redux/index';
import { PersistGate } from 'redux-persist/integration/react';
import ThankYou from './components/BuildWebiste/ThankYou';
import { verifySession, dispatchUserData } from '../src/redux/actions/UserActions';
import React, { useState, useEffect }from 'react';
import { Spinner } from 'react-bootstrap';
import ThemeSinglePage from './components/ThemeSettings/ThemeSinglePage';
import Invoices from './components/ThemeSettings/Invoices';
import ChangeDomain from './components/ThemeSettings/ChangeDomain';
import PurchaseAddons from './components/ThemeSettings/PurchaseAddons';
import ViewUserProfile from './components/UserProfile/ViewUserProfile';
import EnterEmailAddress from './components/ForgotPassword/EnterEmailAddress';
import EnterVerificationCode from './components/ForgotPassword/EnterVerificationCode';
import EnterNewPassword from './components/ForgotPassword/EnterNewPassword';
import ChangePasswordSuccess from './components/ForgotPassword/ChangePasswordSuccess';
import EditUserProfile from './components/UserProfile/EditUserProfile';

function PrivateRoute({ children, ...rest }) {
  
  const userData = useSelector(state => state.user.user_details);
  const dispatch = useDispatch();
  const [isSessionVerified, setSessionVerified] = useState(false);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    getSessionData()
  }, [])
  const getSessionData = async () => {
    if(userData != "NOT LOGIN"){
      console.log('userDatauserDatauserData',userData)
      const session_data = await Promise.resolve(verifySession(userData.session_token, userData.user_id));
      try{
        const {data} = session_data;
        if(data.message == "Valid Session"){
          const {user} = data;
          user.currency_sym = user.currency === "1" ? "$" : user.currency === "2" ? "₱" : "";
          const new_user_data = {
            address: user.address,
            card_details: user.card_details,
            company: user.company,
            currency: user.currency,
            currency_sym: user.currency_sym,
            email_address: user.email_address,
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone,
            session_token: userData.session_token,
            stripe_id: user.stripe_id,
            user_id: user.user_id,
            website_subcription_count: user.website_subcription_count,
            email_verified: user.email_verified
          }
          dispatch(dispatchUserData(new_user_data));
          setSessionVerified(true);
          setLoading(false);
        }
      }catch(ex){
        setSessionVerified(false);
        localStorage.clear();
        setLoading(false);
      }
    }else{
      setLoading(false);
    }
  };

  if(isLoading){
    return(
      <React.Fragment>
        <br/><br/><br/>
        <div  className="vertical-center" style={{width:"100%"}}>
          <center>
            <Spinner animation="grow" variant="danger" size="lg" />
            <Spinner animation="grow" variant="warning" size="lg" />
            <Spinner animation="grow" variant="success" size="lg" />
          </center>
        </div>
      </React.Fragment>
    );
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
      isSessionVerified ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function App() {
  return (
    <Provider store={store}> 
      <Router basename="/">
        <PersistGate persistor={persistor}>
          <Switch>
            <Route exact path="/">
              <SignUpBody />
            </Route>
            <Route exact path="/thank-you-sign-up">
              <SignUpThankYou />
            </Route>
            <Route exact path="/login">
              <LoginBody />
            </Route>
            <Route exact path="/forgot-password/enter-email-address">
              <EnterEmailAddress />
            </Route>
            <Route exact path="/forgot-password/enter-verification-code">
              <EnterVerificationCode />
            </Route>
            <Route exact path="/forgot-password/enter-new-password">
              <EnterNewPassword />
            </Route>
            <Route exact path="/forgot-password/success">
              <ChangePasswordSuccess />
            </Route>
            {/* <Route exact path="/dashboard">
              <Home />
            </Route> */}
            <PrivateRoute path="/dashboard">
              <Home />
            </PrivateRoute>
            <PrivateRoute exact path="/verify-email">
              <VerifyEmail />
            </PrivateRoute>
            <PrivateRoute exact path="/build-website/:id" >
              <BuildWebsiteBody />
            </PrivateRoute>
            <PrivateRoute exact path="/build-website/thank-you/:sub_id" >
              <ThankYou />
            </PrivateRoute>
            <PrivateRoute exact path="/theme-settings" >
              <ThemeSinglePage />
            </PrivateRoute>
            <PrivateRoute exact path="/theme-settings/invoices" >
              <Invoices />
            </PrivateRoute>
            <PrivateRoute exact path="/theme-settings/change-domain" >
              <ChangeDomain />
            </PrivateRoute>
            <PrivateRoute exact path="/theme-settings/purchase-add-ons" >
              <PurchaseAddons />
            </PrivateRoute>
            <PrivateRoute exact path="/my-account" >
              <ViewUserProfile />
            </PrivateRoute>
            <PrivateRoute exact path="/my-account/update" >
              <EditUserProfile />
            </PrivateRoute>
            <Route path="*">
              {
                <center><h1>404 Page not found!</h1></center>
              }
            </Route>
          </Switch>
        </PersistGate>
      </Router>
    </Provider>
  );
}

export default App;
