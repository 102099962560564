import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import Footer from '../Dashboard/Footer';
import Header from '../Dashboard/Header';

function ViewUserProfile() {
    let history = useHistory();
    const userData = useSelector(state => state.user.user_details);
    const [goToVerifyEmail, setGoToVerifyEmail] = useState(false);

    useEffect(() => {
        document.title = "My Account | MyStore"
        history.push("/my-account");
    }, [])

    if(goToVerifyEmail){
        return <Redirect to={{
            pathname: '/verify-email'
        }} />
    }
    return (
        <React.Fragment>
            <Header />
            <section id="__home-verify-email">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="__welcome-message-wrap text-left">
                                <h5>Welcome, {`${userData.first_name} ${userData.last_name}`}</h5>
                                <p className="d-inline-block mr-4">Account Profile</p>
                                <Link className="d-inline-block" to={"/my-account/update"} style={{color: "#fb561f", fontWeight: "500"}}><u>Edit Your Profile</u></Link>
                                {/* <a className="d-inline-block" style={{color: "#fb561f", fontWeight: "500"}} href="#go-back" onClick={(e) => {
                                    e.preventDefault();
                                    window.history.back();
                                }}><u></u></a> */}
                            </div>
                        </div>
                        <div className="col-lg-6 text-right">
                        {/* <Link className="btn btn-primary" to="/">Home</Link> */}
                            {
                                userData.email_verified !== 1 ?
                                    <button className="btn btn-secondary" onClick={() => {setGoToVerifyEmail(true);}}>VERIFY YOUR EMAIL</button>
                                :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section id="__profile" className="mt-4">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-lg-12">
                            <div className="title">
                                <h4>Billing Information</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>Full Name</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <p>{userData.first_name} {userData.last_name}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>Email</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <p>{userData.email_address}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>Phone</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <p>{userData.phone}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>Company</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <p>{userData.company}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>Address</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <p>{userData.address.address_line1} {userData.address.city} {userData.address.state_province} {userData.address.zipcode} {userData.address.country}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mb-4">
                        <div className="col-lg-12">
                            <div className="title">
                                <h4>Card Information</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>Credit Card</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <p>#### #### #### {userData.card_details}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
}

export default ViewUserProfile
