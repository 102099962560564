import React, { useState, useEffect }from 'react';
import RocketIcon from '../../assets/rocket.png';
import MonitorIcon from '../../assets/monitor.png';
import '../../styles/Dashboard.scss';
import Header from './Header';
import Footer from './Footer';
import { Redirect, useHistory, Link } from "react-router-dom";
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getUserSubscriptions, getUserSubscriptionsNextPage } from '../../redux/actions/SubscriptionActions';
import { getAuthorizationToken } from '../../redux/actions/UserActions';
import InfiniteScroll from "react-infinite-scroll-component";
import { dispatchThemeData } from '../../redux/actions/ThemeSettingsActions';
import Moment from 'react-moment';

function Home() {
    let history = useHistory();

    const dispatch = useDispatch();

    const userData = useSelector(state => state.user.user_details);
    const cartData = useSelector(state => state.cart.cart);
    const [goToVerifyEmail, setGoToVerifyEmail] = useState(false);
    const [goToBuildWebsite, setGoToBuildWebsite] = useState(false);
    const [goToThemeSingle, setGoToThemeSingle] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState({
        data: []
    })
    const [selectedThemeData, setThemeData] = useState(0);
    
    const getSubscriptions = async () => {
        setIsLoading(true)
        const authToken = getAuthorizationToken( userData.session_token, userData.user_id);
        const subscriptionsResponse = await Promise.resolve(
            getUserSubscriptions(userData.user_id, authToken)
        )
        const {success, data} = subscriptionsResponse;
        if(success){
            setSubscriptions(data);
            setIsLoading(false);
        }else{
            alert(data);
            setIsLoading(false);
        }
    }

    const fetchMoreData = async (e) => {
        const authToken = getAuthorizationToken(userData.session_token, userData.user_id);
        const subscriptionsResponse = await Promise.resolve(
            getUserSubscriptionsNextPage(subscriptions.next_page_url, authToken)
        )
        const { success, fetched_data } = subscriptionsResponse;
        if(success){
            const merged_data = subscriptions.data.concat(fetched_data.data);
            fetched_data.data = merged_data;
            setSubscriptions(fetched_data);
        }else{
            alert(fetched_data);
        }
    }

    useEffect(() => {
        document.title = "Dashboard | MyStore"
        getSubscriptions();
        history.push("/dashboard");
    }, [])

    const goToThemeSettings = (data) => {
        // setThemeData(id);
        dispatch(dispatchThemeData(data));
        setGoToThemeSingle(true);
    }

    if(goToThemeSingle){
        return <Redirect to={{
            pathname: '/theme-settings',
            state: selectedThemeData
        }} />
    }
    else if(goToVerifyEmail){
        return <Redirect to={{
            pathname: '/verify-email'
        }} />
    }else if(goToBuildWebsite){
        return <Redirect to={cartData.currentPathName || "/build-website/industry"} />
    }
    return (
        <React.Fragment>
            <Header />
            <section id="__home-verify-email">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="__welcome-message-wrap">
                                <h5>Welcome, {`${userData.first_name}`}!</h5>
                                <p>What would you like to do today?</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                        {/* <Link className="btn btn-primary" to="/">Home</Link> */}
                            <div className="__action-wrap">
                            {
                                userData.email_verified !== 1 ?
                                    <button className="btn btn-secondary" onClick={() => {setGoToVerifyEmail(true);}}>VERIFY YOUR EMAIL</button>
                                :
                                    null
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="__home-user-other-actions">
                <div className="container container-fluid-md container-fluid-sm">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="__create-website-wrap">
                                <div className="__message-wrap">
                                    <div>
                                        <img src={RocketIcon} alt=""/> 
                                    </div>
                                    <div>
                                        <h5>Create your online store in 5 minutes <br/>without any coding skills.</h5>
                                    </div>
                                </div>
                                <div className="__action-wrap">
                                    <button className="btn btn-primary" onClick={() => {setGoToBuildWebsite(true);}}>{cartData.currentPathName && cartData.currentPathName != "/build-website/industry" ? "Continue" : "Start Here"}</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="__track-website-wrap">
                                <div className="__message-wrap">
                                    <div>
                                        <img src={MonitorIcon} alt=""/> 
                                    </div>
                                    <div>
                                        <h5>Using our expert design and implementation<br/>
services? Check the status of your project.</h5>
                                    </div>
                                </div>
                                <div className="__action-wrap">
                                    <button className="btn btn-hovered" onClick={() => {alert("This feature is under development.")}}>See Updates</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="__home-user-subscriptions">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-left">
                            <h4 className="m-0">My Themes</h4>
                            <hr className="mt-3 mb-0"/>
                        </div>
                        { !isLoading ?
                        <div className="col-lg-12">
                            {
                                subscriptions.data.length == 0 ? 
                                <React.Fragment>
                                    <br/>
                                    <center><p>No themes found.</p></center>
                                </React.Fragment>
                                :
                                <InfiniteScroll
                                    dataLength={subscriptions.data.length}
                                    next={fetchMoreData.bind(this)}
                                    hasMore={subscriptions.current_page == subscriptions.last_page ? false : true}
                                    loader={<center > <p><Spinner animation="border"></Spinner></p> </center>}
                                >
                                <table style={{width: "100%"}}>
                                    <thead>
                                        <tr className="heading-tbl">
                                            <th style={{minWidth: '145px'}}>Theme</th>
                                            <th style={{minWidth: '400px'}}>Website URL</th>
                                            <th style={{minWidth: '125px'}}>Issue Date</th>
                                            <th style={{minWidth: '125px'}}>Plan Type</th>
                                            <th style={{minWidth: '100px'}}>Status</th>
                                            <th className="actions-lbl" style={{minWidth: '115px'}}>Actions</th>
                                        </tr>
                                    </thead>
                                    {
                                        <tbody>
                                            {
                                                (subscriptions.data || []).map((data, index) => (
                                                    <tr key={index}>
                                                        <td>{data.product.product_name}</td>
                                                        <td>{ data.domain !== null ? <a href={('https://'+data.domain.current_domain_name || '')} target="_blank"><u>{data.domain.current_domain_name || ''}</u></a> : "No URL found"}</td>
                                                        <td><Moment format="MM-DD-YYYY">{data.created_at}</Moment></td>
                                                        <td>{data.plan_type}</td>
                                                        <td className={data.status == 'Active' ? 'active': null}>{data.status}</td>
                                                        <td><button className="btn btn-muted" onClick={() => {goToThemeSettings(data)}}><i class="fa fa-cog" aria-hidden="true"></i> Settings</button></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    }
                                </table>
                                <br />
                                </InfiniteScroll>
                            }
                            
                        </div>
                        : 
                        <div className="col-lg-12 mt-4">
                            <center><Spinner animation="border"></Spinner> </center>
                        </div>
                        }
                    </div>
                </div>
            </section>
            <br/><br/><br/>
            <Footer />
        </React.Fragment>
    )
}

export default Home
