import axios from 'axios';

import {
    USER_LOGIN
} from './Types';

const baseURL = process.env.REACT_APP_API_URL;

export const getAuthorizationToken = (authToken, id) => {
	return btoa(JSON.stringify({
		session_hash: authToken || '',
		user_id: id || ''
	}));
}

export const verifyCredentials = (email, password) => {
    return axios.get(
        baseURL+`/api/user`,
        { params: {email_address: email, password: password, user_role: 1} }
    )
    .then(response => {
        return {
            success: true,
            data: response.data
        }
    })
    .catch(error => {
        return {
            success: false,
            data: error.response.data.message
        }
    })
}

export const verifySession = (session_key, user_id) => {
    console.log('session_keysession_keysadasdsa',session_key)
	return axios.get(baseURL + '/api/token', {
		params: {
			token: session_key,
			user_id: user_id
		}
	});
}

export function updateUser(user_data, authKey) {
    return axios.put(
        baseURL + '/api/user/' + user_data.user_id,
        { ...user_data },
        { headers: { 'Content-Type': 'application/json', Authorization: authKey } }
    );
}

export const dispatchUserData = (data) => {
    return {
        type:    USER_LOGIN,
        payload: data
    }
}