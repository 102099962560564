import {
    THEME_SETTING_DATA
} from '../actions/Types';

const initialState = {
    theme_data: null
}

export default function(state = initialState, {type, payload}){
    switch(type){
        case THEME_SETTING_DATA:
            return {
                ...state,
                theme_data: payload
            }
        default: 
            return state;
    }
}