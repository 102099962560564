import React, { useState,useEffect } from 'react';
import '../../styles/BuildWebsite.scss';
import { Form,Col,InputGroup, OverlayTrigger, Popover } from 'react-bootstrap'
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { dispatchCartData, getPlansTypes, getAdditionalServices, getPromoCodes } from '../../redux/actions/CartActions'
import { getAuthorizationToken } from '../../redux/actions/UserActions';
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from 'react-bootstrap';

function ChoosePlan() {
    const dispatch = useDispatch();
    let cartData =  useSelector(state => state.cart.cart);
    const userData = useSelector(state => state.user.user_details);

    const [isLoading, setLoading] = useState(true);
    const [isCheckingPromoCode, setCheckingPromoCode] = useState(false);
    const [planTypes, setPlanTypes] = useState([]);
    const [additionalServices, setAdditionalServices] = useState({setup_types: [], seo_types: []});
    const [goToCheckout, setGoToCheckout] = useState(false);
    const [goToThemes, setGoToThemes] = useState(false);
    const [goToAddOns, setGoToAddOns] = useState(false);
    

    useEffect(async () => {
        document.title = "Choose Plan | MyStore";
        cartData = {
            ...cartData,
            currentPathName: (window.location.pathname)
        }
        dispatch(dispatchCartData(cartData));

        const authToken = getAuthorizationToken(userData.session_token, userData.user_id);

        const apiResponses = await Promise.all([getPlansTypes(cartData.theme_id), getAdditionalServices(userData.user_id, authToken)]);
        
        const planTypesResponse = apiResponses[0];
        const { success, fetched_data } = planTypesResponse;

        const additionalServicesResponse = apiResponses[1];
        const { as_success, as_fetched_data } = additionalServicesResponse;

        if(success){
            setPlanTypes(fetched_data.plan_types);
        }else{
            alert(fetched_data);
        }

        if(as_success){
            setAdditionalServices(as_fetched_data);
        }else{
            alert(as_fetched_data);
        }

        setLoading(false);
    }, [])
   
    const seoAddOns = [
        {
            id: 1,
            seo_type: "Basic On-Page SEO",
            seo_price: "₱7,599.00"
        },
        {
            id: 2,
            seo_type: "Google Analytics Setup",
            seo_price: "₱3,499.00"
        }
    ]

    const checkPromoCode = async () => {
        setCheckingPromoCode(true);
        const { value } = document.getElementById('promo_code_field');
        if(value == ""){
            alert("Please enter a promo code!");
            setCheckingPromoCode(false);
        }else{
            const authToken = getAuthorizationToken(userData.session_token, userData.user_id);
            const promoCodeResponse = await Promise.resolve(getPromoCodes(userData.user_id, authToken));
            const {success, fetched_data} = promoCodeResponse;
            let validated = false;
            if(success){
                for(let count = 0; count < fetched_data.promo_codes.length; count++){
                    if(fetched_data.promo_codes[count].promo_code === value && fetched_data.promo_codes[count].currency == (userData.currency == "1" ? "USD" : "PHP")){
                        cartData = {
                            ...cartData,
                            promo_code: fetched_data.promo_codes[count],
                        }
                        dispatch(dispatchCartData(cartData));
                        validated = true;
                        break;
                    }
                    else if(fetched_data.promo_codes[count].promo_code === value && (fetched_data.promo_codes[count].currency == null || fetched_data.promo_codes[count].currency == "")){
                        cartData = {
                            ...cartData,
                            promo_code: fetched_data.promo_codes[count],
                        }
                        dispatch(dispatchCartData(cartData));
                        validated = true;
                        break;
                    }
                }
            }else{
                alert(fetched_data);
            }

            if(!validated){
                alert('Promo code invalid!');
            }

            setCheckingPromoCode(false);

            console.log(promoCodeResponse);
        }
    }

    const handleAddPlan = (id, plan, price) => {
        cartData = {
            ...cartData,
            plan_id: id,
            plan_type: plan,
            plan_price: price
        }
        dispatch(dispatchCartData(cartData));
    }

    const handleAddSetupType = (plan, price, description) => {
        if(cartData.setup_type != plan){
            cartData = {
                ...cartData,
                setup_description: description,
                setup_type: plan,
                setup_price: price
            }
            dispatch(dispatchCartData(cartData));
        }else{
            handleRemoveSetupType();
        }
    }

    const handleRemoveSetupType = () => {
        cartData = {
            ...cartData,
            setup_description: "",
            setup_type: "",
            setup_price: ""
        }
        dispatch(dispatchCartData(cartData));
    }

    const handleAddSeoType = (type_id, price) => {
        if((cartData.picked_seo_types.filter(({id}) => id == type_id)).length > 0){
            const picked_seo_types = cartData.picked_seo_types.filter(({id}) => id != type_id);
            cartData = {
                ...cartData,
                picked_seo_types: picked_seo_types
            }
            dispatch(dispatchCartData(cartData));
        }else{
            let { picked_seo_types } = cartData;
            try {
                if((picked_seo_types.filter(({id}) => id == type_id)).length === 0) {
                    picked_seo_types.push(
                        (additionalServices.seo_types.filter(({id}) => id == type_id))[0]
                    );
                    cartData = {
                        ...cartData,
                        picked_seo_types: picked_seo_types
                    }
                    dispatch(dispatchCartData(cartData));
                }
            }catch(ex){
    
            }
        }
        
    }

    const formatAmount = (amount) => {
        const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        return priceSplitter(parseFloat(Math.round(amount * 100) / 100).toFixed(2))
    }

    if(goToCheckout){
        return <Redirect to='/build-website/checkout' />
    }else if(goToThemes){
        return <Redirect to={{
            pathname: '/build-website/themes',
        }}/>
    }else if(goToAddOns){
        return <Redirect to={{
            pathname: '/build-website/add-ons',
        }}/>
    }
    return (
        isLoading ?
        <React.Fragment>
            <br/><br/><br/>
            <center ><p><Spinner animation="border"></Spinner></p> </center>
        </React.Fragment>
        :
        <React.Fragment>
            <section id="__choose-plan">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="plan-picklist">
                                <div className="title">
                                    <h4>Choose Below</h4>
                                </div>
                                <div className="question">
                                    <h4>Which plan works best for you?</h4>
                                </div>
                                {
                                    (planTypes || []).map((data, index) => (
                                        <div className="product">
                                            <div className="product-details">
                                                <div className="product-name">
                                                    <h4>{data.type} | {userData.currency_sym}{formatAmount(data.price)}/month</h4>
                                                </div>
                                                <div className="product-description">
                                                    {
                                                        data.type == "Premium" ? 
                                                        <p>Advanced Features tailor-fit for every online seller.</p>
                                                        :
                                                        data.type == "Professional" ? 
                                                        <p>Features and integrations to help businesses grow.</p>
                                                        :
                                                        data.type == "Plus" ? 
                                                        <p>Basic features for new online sellers.</p>
                                                        :
                                                        data.type == "Starter" ? 
                                                        <p>Basic features for social sellers.</p>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                            <div className="product-action">
                                                <a href="#add-to-cart" onClick={(e) => {e.preventDefault(); handleAddPlan(data.stripe_plan_id, data.type, data.price)}}><u>{cartData.plan_type === data.type ? "ADDED" : "ADD TO CART"}</u></a>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="question">
                                    <h4>Need setup help from our team of experts?</h4>
                                </div>
                                {
                                    additionalServices.setup_types.map((data, index) => (
                                        <div className="product">
                                            <div className="product-details">
                                                <div className="product-name">
                                                    <h4>
                                                        {data.string_description} 
                                                       
                                                        <OverlayTrigger
                                                            trigger="hover"
                                                            placement={"top"}
                                                            overlay={
                                                                <Popover id="popover-contained">
                                                                    <div className="additional-service-wrap" dangerouslySetInnerHTML={{__html: data.html_description}}>
                                                                    </div>
                                                                </Popover>
                                                            }
                                                        >
                                                            <i className="fa fa-question-circle" aria-hidden="true" style={{color: "656565"}}></i>
                                                        </OverlayTrigger>
                                                    </h4>
                                                </div>
                                                <div className="product-description">
                                                    {/* <p>Ut enim ad minim veniam, quis nostrud exercitation.</p> */}
                                                </div>
                                            </div>
                                            <div className="product-action">
                                                <a href="#add-to-cart" className={cartData.setup_type === data.type ? "remove" : ""} onClick={(e) => {e.preventDefault(); handleAddSetupType(data.type, data.price, data.string_description)}}>
                                                    <u>{cartData.setup_type === data.type ? "REMOVE" : "ADD TO CART"}</u>
                                                </a>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="question">
                                    <h4>Need Search Engine Optimization (SEO)?</h4>
                                </div>
                                {
                                    additionalServices.seo_types.map((item, index) => (
                                        <div className="product">
                                            <div className="product-details">
                                                <div className="product-name">
                                                    <h4>
                                                        {item.string_description}
                                                        <OverlayTrigger
                                                            trigger="hover"
                                                            placement={"top"}
                                                            overlay={
                                                                <Popover id="popover-contained">
                                                                    <div className="additional-service-wrap" dangerouslySetInnerHTML={{__html: item.html_description}}>
                                                                    </div>
                                                                </Popover>
                                                            }
                                                        >
                                                            <i className="fa fa-question-circle" aria-hidden="true" style={{color: "656565"}}></i>
                                                        </OverlayTrigger>
                                                    </h4>
                                                </div>
                                                <div className="product-description">
                                                    {/* <p>Ut enim ad minim veniam, quis nostrud exercitation.</p> */}
                                                </div>
                                            </div>
                                            <div className="product-action">
                                                <a href="#add-to-cart" className={(cartData.picked_seo_types.filter(({id}) => id == item.id)).length > 0 ? 'remove' : ''} onClick={(e) => {e.preventDefault(); handleAddSeoType(item.id, item.price)}}>
                                                    <u>{(cartData.picked_seo_types.filter(({id}) => id == item.id)).length > 0 ? 'REMOVE' : 'ADD TO CART'}</u>
                                                </a>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="order-summary">
                                <div className="promo-code-form">
                                    <Form>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="promo_code_field">
                                                <InputGroup >
                                                    <Form.Control type="text" aria-describedby="inputGroupPrepend" placeholder="PROMO CODE" />
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="inputGroupPrepend" onClick={() => checkPromoCode()} disable={isCheckingPromoCode}>{isCheckingPromoCode ? <Spinner animation="border" size="sm"></Spinner>: "ADD"}</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control.Feedback type="invalid" >
                                                        Please provide a valid Promo code.
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Form.Row>
                                    </Form>
                                </div>
                                <div className="order-summary-content">
                                    <div className="title text-center">
                                        <h4>Order Summary</h4>
                                    </div>
                                    <div className="cart">
                                        <div className="cart-item">
                                            <div>
                                                <label>Theme</label>
                                            </div>
                                            <div className="item-details">
                                                <p className="item-name">{cartData.theme_name}</p><p className="item-price"></p>
                                            </div>
                                        </div>
                                        <div className="cart-item">
                                            <div>
                                                <label>Plan Type</label>
                                            </div>
                                            <div className="item-details">
                                                <p className="item-name">{cartData.plan_type}</p><p className="item-price">{cartData.plan_price != '' ? userData.currency_sym : null}{formatAmount(cartData.plan_price)}</p>
                                            </div>
                                        </div>
                                        <div className="cart-item">
                                            <div>
                                                <label>Additional Services</label>
                                            </div>
                                            <div className="item-details">
                                                <p className="item-name">{cartData.setup_type !== '' ? `${cartData.setup_description.split('|')[0]} Setup` : null} </p><p className="item-price">{cartData.setup_type !== '' ? cartData.setup_description.split('|')[1] : null}</p>
                                            </div>
                                            {
                                                cartData.picked_seo_types.map((item, index) => (
                                                    <div className="item-details">
                                                        <p className="item-name">{item.string_description.split('|')[0]}</p><p className="item-price">{item.string_description.split('|')[1]}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="cart-item mt-4">
                                            <div className="item-details">
                                                <p className="item-name"><b>Promo Code</b></p><p className="item-price font-weight-normal">{cartData.promo_code.promo_code || null}</p>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="cart-action">
                                            <button className="btn btn-primary" onClick={() => {
                                                if(cartData.plan_type != ""){
                                                    setGoToCheckout(true)
                                                }else{
                                                    alert("Please select a plan!")
                                                }
                                                
                                            }}>
                                                CHECKOUT
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="__action-wrap" className="fixed-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 ">
                            <div className="">
                                <button className="btn btn-muted float-left" onClick={() => {setGoToThemes(true)}}>BACK</button>
                                <button className="btn btn-primary float-right" onClick={() => {
                                    if(cartData.plan_type != ""){
                                        setGoToAddOns(true)
                                    }else{
                                        alert("Please select a plan!")
                                    }
                                }}>CONTINUE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ChoosePlan
