import React, {useState} from 'react';
import PaymentMethodLogo from '../../assets/visa-master-logo.png'
import { useSelector, useDispatch } from 'react-redux';
import {Elements, CardElement, useStripe, useElements, ElementsConsumer} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Redirect } from "react-router-dom";
import {createCustomerPaymentMethod, createCustomerSubscription, createWebsite} from '../../redux/actions/CheckoutActions';
import { dispatchCartData} from '../../redux/actions/CartActions'
import { getAuthorizationToken } from '../../redux/actions/UserActions';
import { Spinner } from 'react-bootstrap';

function Checkout() {
    const stripe_pk = process.env.REACT_APP_STRIPE_PK;
    const stripePromise = loadStripe(stripe_pk);

    

    const CARD_ELEMENT_OPTIONS = {
        style: {
          base: {
            color: "#666666",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#666666",
            },
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
          },
        },
        hidePostalCode: true
    };

    const dispatch = useDispatch();
    let cartData =  useSelector(state => state.cart.cart);
    const userData = useSelector(state => state.user.user_details);
    const [goToThemes, setGoToThemes] = useState(false);
    const [isPaymentLoading, setPaymentLoading] = useState(false);

    const executeStripeCheckout = async (stripe, elements) => {
        setPaymentLoading(true);

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            alert("Error connecting to payment gateway, please try again.")
            setPaymentLoading(false);
            return;
        }
        console.log('userDatauserDatauserData', userData.stripe_id === null || userData.stripe_id === "");
        const authToken = getAuthorizationToken(userData.session_token, userData.user_id);
        if(userData.stripe_id === null || userData.stripe_id === ""){
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                    email: userData.email_address,
                },
            });
            if(result.error){
                setPaymentLoading(false);
                alert(result.error.message);
            }
            else{
                console.log('resultresultresultresult',result);
                const paymentMethod = await Promise.resolve(createCustomerPaymentMethod(stripe, elements, userData.user_id, authToken, result));
                // console.log("paymentMethod",paymentMethod);
                const { status } = paymentMethod;
                if(status){
                    const { pm_id } = paymentMethod;
                    //CREATE CUSTOMER SUBSCRIPTION
                    const customerSubsResponse = await Promise.resolve(createCustomerSubscription(cartData, pm_id, userData.user_id, authToken));
                    const { status, data } = customerSubsResponse;
                    if(status){
                        handleCreateSubscriptionResponse(data, stripe);
                    }else{
                        setPaymentLoading(false);
                        alert(data);
                    }
                    
                }else{
                    const { data } = paymentMethod;
                    setPaymentLoading(false);
                    alert(data);
                }
            }
        }
        else{
            const customerSubsResponse = await Promise.resolve(createCustomerSubscription(cartData, userData.stripe_id, userData.user_id, authToken));
            const { status, data } = customerSubsResponse;
            if(status){
                handleCreateSubscriptionResponse(data, stripe);
            }else{
                setPaymentLoading(false);
                alert(data);
            }
        }    
    };

     const handleCreateSubscriptionResponse = (response, stripe) => {
        try
        {
            const { latest_invoice } = response;
            const { payment_intent } = latest_invoice;
            const subscription_id = response.id;
    
            if (payment_intent) {
                const { client_secret, status } = payment_intent;
                if (status === 'requires_action') {
                    stripe.confirmCardPayment(client_secret).then(result => {
                    if (result.error) {
                        setPaymentLoading(false);
                        alert(result.error);
                        console.log('confirmCardPayment error', result)
                    } else {
                        handleSuccessStripePayment(subscription_id);
                    }
                    });
                } else {
                    handleSuccessStripePayment(subscription_id);
                }
            }
            else if(payment_intent == null){
                handleSuccessStripePayment(subscription_id);
            }
        }
        catch(ex)
        {
            // call delete customer stripe_id
            // call delete subscription where status = null
            setPaymentLoading(false);
            alert("Your payment was declined. Please try again.");
        }
        
    }

    const handleSuccessStripePayment = async (subscription_id) => {
        const authToken = getAuthorizationToken(userData.session_token, userData.user_id);
        const createWebsiteResponse = await Promise.resolve(createWebsite(userData.user_id, authToken, subscription_id));
        const { success } = createWebsiteResponse;
        if(success){
            const { url } = createWebsiteResponse;
            const cartData = {
                currentPathName: "/build-website/industry",
                industry: "",
                theme_id: "",
                theme_name: "",
                plan_type: "",
                plan_price: "",
                setup_type: "",
                setup_price: "",
                picked_seo_types: [],
                promo_code: "",
                pickedAddons: [],
                purchase_addon_cart: {
                    pickedAddons: []
                }
            }
            dispatch(dispatchCartData(cartData));
            window.location.replace(url);
        }else{
            const { message } = createWebsiteResponse;
            alert(message);
        }
    }

    const formatAmount = (amount) => {
        const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        return priceSplitter(parseFloat(Math.round(amount * 100) / 100).toFixed(2))
    }
    
    if(goToThemes){
        return <Redirect to={{
            pathname: '/build-website/themes',
        }}/>
    }
    return (
        <React.Fragment>
            <section id="__checkout">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <Elements stripe={stripePromise}>
                                <div className="credit-card-wrap">
                                    <div className="title">
                                        <h4>Payment Method</h4>
                                    </div>
                                    <div className="payment-methods">
                                        <img src={PaymentMethodLogo} alt="PaymentMethodLogo"/>
                                    </div>
                                    <div>
                                        <hr/>
                                    </div>
                                    <div className="cc-field-wrap">
                                        {
                                            userData.stripe_id == null || userData.stripe_id == "" ?
                                                <React.Fragment>
                                                    <label><b>Credit Card Information</b></label>
                                                    <CardElement className options={CARD_ELEMENT_OPTIONS} className="input"/>
                                                </React.Fragment>
                                            :
                                                <React.Fragment>
                                                    <label><b>Saved Card Number</b></label>
                                                    <div>
                                                        <input type="text" className="input" style={{width: "100%"}} value={`#### #### #### ${userData.card_details}`} disabled={true}/>
                                                    </div>
                                                </React.Fragment>
                                        }
                                        
                                    </div>
                                    <div className="action">
                                        <ElementsConsumer>
                                            {({stripe, elements}) => (
                                                <button className="btn btn-primary" onClick={() => {executeStripeCheckout(stripe, elements)}} disabled={isPaymentLoading}>{isPaymentLoading ? <Spinner animation="border" /> : 'PLACE ORDER'}</button>
                                            )}
                                        </ElementsConsumer>
                                    </div>
                                </div>
                            </Elements>   
                        </div>
                        <div className="col-lg-4">
                            <div className="order-summary">
                                <div className="order-summary-content">
                                    <div className="title text-center">
                                        <h4>Order Summary</h4>
                                    </div>
                                    <div className="cart">
                                        <div className="cart-item">
                                            <div>
                                                <label>Theme</label>
                                            </div>
                                            <div className="item-details">
                                                <p className="item-name">{cartData.theme_name}</p><p className="item-price"></p>
                                            </div>
                                        </div>
                                        <div className="cart-item">
                                            <div>
                                                <label>Plan Type</label>
                                            </div>
                                            <div className="item-details">
                                                <p className="item-name">{cartData.plan_type}</p><p className="item-price">{cartData.plan_price != '' ? userData.currency_sym : null}{formatAmount(cartData.plan_price)}</p>
                                            </div>
                                        </div>
                                        <div className="cart-item">
                                            <div>
                                                <label>Additional Services</label>
                                            </div>
                                            <div className="item-details">
                                                <p className="item-name">{cartData.setup_type !== '' ? `${cartData.setup_description.split('|')[0]} Setup` : null} </p><p className="item-price">{cartData.setup_type !== '' ? cartData.setup_description.split('|')[1] : null}</p>
                                            </div>
                                            {
                                                cartData.picked_seo_types.map((item, index) => (
                                                    <div className="item-details">
                                                        <p className="item-name">{item.string_description.split('|')[0]}</p><p className="item-price">{item.string_description.split('|')[1]}</p>
                                                    </div>
                                                ))
                                            }
                                            
                                        </div>
                                        <div className="cart-item">
                                            <div>
                                                <label>Additional Features</label>
                                            </div>
                                            {
                                                cartData.pickedAddons.map((item, index) => (
                                                    <div className="item-details">
                                                        {
                                                            item.addon_code == "boss_app" ? 
                                                                <React.Fragment>
                                                                    <p className="item-name">{item.name} x {item.quantity}</p><p className="item-price">{item.price != '' ? userData.currency_sym : null}{formatAmount(item.price * item.quantity)}</p>
                                                                </React.Fragment>
                                                            :
                                                                <React.Fragment>
                                                                    <p className="item-name">{item.name}</p><p className="item-price">{item.price != '' ? userData.currency_sym : null}{formatAmount(item.price)}</p>
                                                                </React.Fragment>
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <hr/>
                                        <div className="cart-item mt-4">
                                            <div className="item-details">
                                                <p className="item-name"><b>Promo Code</b></p><p className="item-price font-weight-normal">{cartData.promo_code.promo_code || 'None'}</p>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <center><a href="#edit-cart" onClick={(e) => {e.preventDefault(); setGoToThemes(true)}}><u>EDIT CART</u></a></center>
                                        </div>
                                        
                                        {/* <div className="cart-action">
                                            <button className="btn btn-primary" onClick={() => {setGoToCheckout(true)}}>CHECKOUT</button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </React.Fragment>
    )
}

export default Checkout
