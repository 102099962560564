import React, {useState,useEffect} from 'react'
import '../../styles/BuildWebsite.scss'
import SampleFeaturedLogo from '../../assets/theme-sample-featured.png'
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { dispatchCartData, getThemeListing, getThemeLisingPage } from '../../redux/actions/CartActions'
import { getAuthorizationToken } from '../../redux/actions/UserActions';
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from 'react-bootstrap';

function SelectTheme() {
    const dispatch = useDispatch();
    let cartData =  useSelector(state => state.cart.cart);
    const userData = useSelector(state => state.user.user_details);

    useEffect(() => {
        getThemes();
        document.title = "Select Theme | MyStore";
        cartData = {
            ...cartData,
            currentPathName: (window.location.pathname)
        }
        dispatch(dispatchCartData(cartData));
    }, [])

    const [goToPlans, setGoToPlans] = useState(false);
    const [goToIndustry, setGoToIndustry] = useState(false);
    const [themes, setThemes] = useState({ data: [] });
    const [isLoading, setIsLoading] = useState(false);

    const getThemes = async () => {
        setIsLoading(true);
        console.log('userData', userData);
        const authToken = getAuthorizationToken(userData.session_token, userData.user_id);
        console.log('authToken', authToken);
        const themesResponse = await Promise.resolve(
            getThemeListing(userData.user_id, userData.currency, authToken)
        );
        const { success, fetched_data } = themesResponse;
        if(success){
            if(localStorage.getItem("themeIdToAdd") != null){
                const themeData = fetched_data.data.filter((item, index) => item.product_id == parseInt(localStorage.getItem("themeIdToAdd")));

                if(themeData.length > 0){
                    cartData = {
                        ...cartData,
                        theme_id: themeData[0].product_id,
                        theme_name: themeData[0].product_name,
                        theme_type_id: themeData[0].product_type_id
                    }
                    dispatch(dispatchCartData(cartData));
                }

                localStorage.setItem("loginRedirectURL", null);
                localStorage.setItem("themeIdToAdd", null);
                localStorage.setItem("themeTypeIdToAdd", null);
            }
            setThemes(fetched_data);
            setIsLoading(false);
        }else{
            alert(fetched_data);
            setIsLoading(false);
        }

        console.log('themes', themesResponse);
    }

    const fetchMoreData = async (e) => {
        const authToken = getAuthorizationToken(userData.session_token, userData.user_id);
        const themesResponse = await Promise.resolve(
            getThemeLisingPage(themes.next_page_url, authToken)
        )
        const { success, fetched_data } = themesResponse;
        if(success){
            const merged_data = themes.data.concat(fetched_data.data);
            fetched_data.data = merged_data;
            setThemes(fetched_data);
        }else{
            alert(fetched_data);
        }
    }

    const handleAddTheme = (e, product_type_id) => {
        e.preventDefault();
        cartData = {
            ...cartData,
            theme_id: e.target.id,
            theme_name: e.target.name,
            theme_type_id: product_type_id
        }
        dispatch(dispatchCartData(cartData));
    }

    const handleGoToPlans = () => {
        if(cartData.theme_name === ""){
            alert('Please select a theme!');
        }else{
            setGoToPlans(true);
        }
    }

    if(goToPlans){
        return <Redirect to={{
            pathname: '/build-website/plans',
        }}
        />
    }else if(goToIndustry){
        return <Redirect to='/build-website/industry' />
    }
    return (
        <React.Fragment>
            <section id="__theme-select">
                { !isLoading ?
                <InfiniteScroll
                    dataLength={themes.data.length}
                    next={fetchMoreData.bind(this)}
                    hasMore={themes.current_page == themes.last_page ? false : true}
                    loader={<center > <p><Spinner animation="border"></Spinner></p> </center>}
                >
                <div className="container">
                    <div className="row">
                        {
                            (themes.data || []).map((data, index) => (
                                <div className="col-lg-4 mb-5">
                                    <div className="theme-card">
                                        <div className="featured-image">
                                            <img src={data.product_thumbnail_link} alt={data.product_thumbnail_link} height="333.15"/>
                                        </div>
                                        <div className="theme-name text-center">
                                            <h5>{data.product_name}</h5>
                                        </div>
                                        <div className="theme-description text-center">
                                            <p>{data.description}</p>
                                        </div>
                                        <div className="action-wrap text-center">
                                            <a href="#add-to-cart" id={data.product_id} name={data.product_name} onClick={(e) => {handleAddTheme(e, data.product_type_id)}}>{cartData.theme_name === data.product_name ? "ADDED" : "ADD TO CART"}</a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                </InfiniteScroll>  
                : 
                <div>
                    <center><Spinner animation="border"></Spinner> </center>
                </div>
                } 
            </section>
            <section id="__action-wrap" className="fixed-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-right">
                            <button className="btn btn-muted" onClick={() => {setGoToIndustry(true)}}>BACK</button>
                        </div>
                        <div className="col-lg-6 text-left">
                            <button className="btn btn-primary" onClick={() => {handleGoToPlans()}}>CONTINUE</button>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default SelectTheme
