import {
    CART_UPDATE
} from '../actions/Types';

const initialState = {
    cart: {
        currentPathName: "/build-website/industry",
        industry: "",
        theme_id: "",
        theme_name: "",
        theme_type_id: "",
        plan_type: "",
        plan_price: "",
        setup_type: "",
        setup_price: "",
        picked_seo_types: [],
        promo_code: "",
        pickedAddons: [],
        purchase_addon_cart: {
            pickedAddons: []
        }
    }
}

export default function(state = initialState, {type, payload}){
    switch(type){
        case CART_UPDATE:
            return {
                ...state,
                cart: payload
            }
        default: 
            return state;
    }
}