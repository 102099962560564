import React, { useEffect, useState } from 'react'
import HeaderLogo from '../../assets/my-store-logo-1.png'
import FooterLogo from '../../assets/prosperna-footer-logo.png'
import { Redirect } from 'react-router-dom';

function SignUpThankYou() {
    const [goToLogin, setGoToLogin] = useState(false);

    useEffect(() => {
        document.title = "Thank you! | MyStore"
    }, [])
    if(goToLogin) {
        return <Redirect to='/login' />
    }
    return (
        <section id="__request-finished">
            <div className="container">
                <div className="row __header">
                    <img src={HeaderLogo} alt="my-store-logo-1"/>
                </div>
                <div className="row __body pb-4">
                    <div className="__message-wrap">
                        <h1>Thank you for signing up!</h1>
                        <p>Continue setting up your online store by completing your account setup, <br/>selecting your theme and confirming your plan.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="__button-wrap">
                        <button className="btn btn-primary" onClick={() => {setGoToLogin(true)}}>LOG IN</button>
                    </div>
                </div>
                <div className="row __footer">
                    <p>Powered by <img src={FooterLogo} alt="prosperna-footer-logo"/></p>
                </div>
            </div>
        </section>
    )
}
export default SignUpThankYou
