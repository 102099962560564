import React, { useEffect, useState } from 'react'
import {
    Link,
    useParams
} from "react-router-dom";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Header from '../Dashboard/Header';
import Footer from '../Dashboard/Footer';
import ThemeSettingsIcon from '../../assets/theme-settings-icon.png'

function ThemeSinglePage() {
    //--------for future upgrade---------
    // let { sub_id } = useParams();
    // sub_id = atob(sub_id);
    // ----------------------------------

    let location = useLocation();
    let history = useHistory();

    const userData = useSelector(state => state.user.user_details);
    const themeData = useSelector(state => state.theme.theme_data);

    const [goToInvoices, setGoToInvoices] = useState(false);
    const [goToChangeDomain, setGoToChangeDomain] = useState(false);

    useEffect(() => {
        history.push("/theme-settings");
    }, [])

    const upgradeToPremium = () => {

    }

    if(goToInvoices){
        return <Redirect to={{
            pathname: '/theme-settings/invoices'
        }} />
    }else if(goToChangeDomain){
        return <Redirect to={{
            pathname: '/theme-settings/change-domain'
        }} />
    }
    return (
        <React.Fragment>
            <Header />
            <section id="__theme-single-upgrade">
                <div className="container">
                    {/* <h1>{sub_id}</h1> */}
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="__welcome-message-wrap text-left">
                                <h5>Welcome, {`${userData.first_name} ${userData.last_name}`}</h5>
                                <p>What would you like to do with your theme?</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="__action-wrap text-right">
                                <a href={'https://'+themeData.domain.current_domain_name} target="_blank"><u>View My Website</u></a>
                                {
                                    themeData.plan_type !== "Premium" ?
                                        <button className="btn btn-primary" onClick={() => {upgradeToPremium()}}>UPGRADE TO PREMIUM</button>
                                    :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="__theme-single-actions">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="action-card text-center">
                                <div className="title-wrap">
                                    <img src={ThemeSettingsIcon} alt="ThemeSettingsIcon"/>
                                    <h4>Add Features</h4>
                                </div>
                                <div className="action-wrap">
                                    <button className="btn btn-hovered"><Link to="/theme-settings/purchase-add-ons">Buy Add-Ons</Link></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="action-card text-center">
                                <div className="title-wrap">
                                    <img src={ThemeSettingsIcon} alt="ThemeSettingsIcon"/>
                                    <h4>Change Domain</h4>
                                </div>
                                <div className="action-wrap">
                                    <button className="btn btn-hovered" onClick={() => {
                                        setGoToChangeDomain(true);
                                    }}>Change</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="action-card text-center">
                                <div className="title-wrap">
                                    <img src={ThemeSettingsIcon} alt="ThemeSettingsIcon"/>
                                    <h4>View Invoices</h4>
                                </div>
                                <div className="action-wrap">
                                    <button className="btn btn-hovered" onClick={() => {
                                        setGoToInvoices(true);
                                    }}>View</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="__theme-single-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-left">
                            <h4 className="m-0">Theme Subscription Details</h4>
                            <hr className="mt-3 mb-0"/>
                        </div>
                        <div className="col-lg-12">
                            <table style={{width: "100%"}}>
                                <thead>
                                    <tr className="heading-tbl">
                                        <th style={{minWidth: '145px'}}>Theme</th>
                                        <th style={{minWidth: '400px'}}>Description</th>
                                        <th style={{minWidth: '125px'}}>Issue Date</th>
                                        <th style={{minWidth: '125px'}}>Plan Type</th>
                                        <th style={{minWidth: '100px'}}>Status</th>
                                    </tr>
                                </thead>
                                {
                                    <tbody>
                                        <tr>
                                            <td>{themeData.product.product_name}</td>
                                            <td>{themeData.product.description}</td>
                                            <td>{themeData.created_at}</td>
                                            <td>{themeData.plan_type}</td>
                                            <td className={themeData.status == 'Active' ? 'active': null}>{themeData.status}</td>
                                        </tr>
                                    </tbody>
                                }
                            </table>
                            <br />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
}

export default ThemeSinglePage
