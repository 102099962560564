import React, { useState,useEffect } from 'react';
import '../../styles/BuildWebsite.scss';
import { Form,Col,InputGroup } from 'react-bootstrap'
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { dispatchCartData, getPlansTypes, getAdditionalServices, getPromoCodes, getAllAddons } from '../../redux/actions/CartActions'
import { getAuthorizationToken } from '../../redux/actions/UserActions';
import { Spinner } from 'react-bootstrap';
import Footer from '../Dashboard/Footer'
import Header from '../Dashboard/Header'
import axios from 'axios';
import { dispatchThemeData } from '../../redux/actions/ThemeSettingsActions';

function PurchaseAddons() {
    const baseURL = process.env.REACT_APP_API_URL;
    const dispatch = useDispatch();
    let cartData =  useSelector(state => state.cart.cart.purchase_addon_cart);
    const themeData = useSelector(state => state.theme.theme_data);
    const addon_feature_ids = JSON.parse(themeData.addon_feature_ids);
    let pickedAddons = cartData.pickedAddons || [];
    const userData = useSelector(state => state.user.user_details);

    const [isFetchingAddons, setFetchingAddons] = useState(true);
    const [addons, setAddons] = useState([]);
    const [isCheckingPromoCode, setCheckingPromoCode] = useState(false);
    const [goToCheckout, setGoToCheckout] = useState(false);
    const [goToThemes, setGoToThemes] = useState(false);
    const [goToAddOns, setGoToAddOns] = useState(false);
    const [bossAppQty, setBossAppQty] = useState(1);
    // const [pickedAddons, setPickedAddons] = useState([]);

    useEffect(async () => {
        const authToken = getAuthorizationToken(userData.session_token, userData.user_id); 
        const getAllAddonsResponse = await Promise.resolve(getAllAddons(userData.user_id, authToken));
        const {success, fetched_data} = getAllAddonsResponse;
        if(success){
            setAddons(fetched_data);
            const test = fetched_data.filter((data, index) =>  
                data.available_plan_types.includes(themeData.plan_type) && 
                data.is_one_payment == 0 && 
                data.one_time_id != null && 
                data.product_type_ids.includes(themeData.product.product_type_id) && 
                !addon_feature_ids.includes(data.id) 
                ||
                data.available_plan_types.includes(themeData.plan_type) && 
                data.is_one_payment == 0 && 
                data.one_time_id == null && 
                data.product_type_ids.includes(themeData.product.product_type_id) && 
                !addon_feature_ids.includes(data.id)
                ? 
                data 
                : 
                null
            );
            if(test.length == 0){
                alert("You already purchased all of the add-ons.")
                window.history.back();
            }
        }else{
            alert(fetched_data);
        }
        setFetchingAddons(false);
        console.log('setFetchingAddons',addons);
        for(let count = 0; count < pickedAddons.length; count++){
            const {addon_code, one_time_id, is_one_payment, name, price} = pickedAddons[count];
            if(document.getElementById(`inline-${addon_code}-monthly`) != null){
                if(is_one_payment == 0){
                    document.getElementById(`${addon_code}-description`).innerText = `${name} | ${userData.currency_sym}${formatAmount(price)}/month`;
                    document.getElementById(`inline-${addon_code}-monthly`).checked = true;
                    document.getElementById(`inline-${addon_code}-one-time`).checked = false;
                }
                
                if(is_one_payment == 1){
                    if(one_time_id != null){
                        const one_time_data = addons.filter(({id}) => 
                            one_time_id == id
                        )[0]
                        document.getElementById(`${addon_code}-description`).innerText = `${one_time_data.name} | ${userData.currency_sym}${formatAmount(one_time_data.price)}`;
                    }
                    document.getElementById(`inline-${addon_code}-monthly`).checked = false;
                    document.getElementById(`inline-${addon_code}-one-time`).checked = true;
                }
            }
        }
    }, [])

    const executeUpdgrade = () => {
        const authToken = getAuthorizationToken( userData.session_token, userData.user_id);
        return axios
        .post(baseURL+'/api/subscription/plan/upgrade/'+userData.user_id, 
        {
            subscription_id: themeData.stripe_subscription_id,
            product_id: themeData.product.product_id,
            customer_id: userData.stripe_id,
            additional_feature: JSON.stringify(pickedAddons),
            addon_only: true
            // boss_app_qty: this.state.boss_app_qty
        },{
            headers: {'Content-Type':'application/json', Authorization: authToken}
        })
        .then(response => {
            if(response.data.success){
                const addon_feature_ids = JSON.parse(themeData.addon_feature_ids);
                pickedAddons.forEach((item, idex) => {
                    addon_feature_ids.push(item.id)
                });
                themeData.addon_feature_ids = JSON.stringify(addon_feature_ids);
                dispatch(dispatchThemeData(themeData));
                cartData = {
                    ...cartData,
                    purchase_addon_cart: {
                        pickedAddons: []
                    }
                }
                dispatch(dispatchCartData(cartData));
                alert('Add-on Purchased Successfully!');
                window.history.back();
            }
        })
        .catch(response => {
            alert('Server Error! ' + response.message)
        });
    }

    const handleAddonType = (id, data) => {
        const {addon_code, one_time_id} = data;
        if(`inline-${addon_code}-monthly` == id){
            document.getElementById(`${addon_code}-description`).innerText = `${data.name} | ${userData.currency_sym}${formatAmount(data.price)}/month`;
            document.getElementById(`inline-${addon_code}-monthly`).checked = true;
            document.getElementById(`inline-${addon_code}-one-time`).checked = false;
        }
        
        if(`inline-${addon_code}-one-time` == id){
            if(one_time_id != null){
                const one_time_data = addons.filter(({id}) => 
                    one_time_id == id
                )[0]
                document.getElementById(`${addon_code}-description`).innerText = `${one_time_data.name} | ${userData.currency_sym}${formatAmount(one_time_data.price)}`;
            }
            document.getElementById(`inline-${addon_code}-monthly`).checked = false;
            document.getElementById(`inline-${addon_code}-one-time`).checked = true;
        }
    }

    const handleAddAddons = (selectedAddon) => {
        const {addon_code} = selectedAddon;
        let addonToBeAdd = null;
        if(document.getElementById(`inline-${addon_code}-monthly`) == null){
            addonToBeAdd = selectedAddon;
        }else{
            if(document.getElementById(`inline-${addon_code}-monthly`).checked){
                addonToBeAdd = selectedAddon;
            }else if(document.getElementById(`inline-${addon_code}-one-time`).checked){
                addonToBeAdd = addons.filter(({id}) => 
                    selectedAddon.one_time_id == id
                )[0]
                
            }else{
                alert("Please select add-on type");
            }
        }
        if(addonToBeAdd != null){
            if(addonToBeAdd.addon_code == "boss_app"){
                addonToBeAdd.quantity = bossAppQty;
            }else{
                addonToBeAdd.quantity = 1;
            }

            if((pickedAddons.filter(({id}) => id == addonToBeAdd.one_time_id)).length > 0){
                const newPickedAddons = pickedAddons.filter(({id}) => id != addonToBeAdd.one_time_id);
                cartData = {
                    ...cartData,
                    purchase_addon_cart: {
                        pickedAddons: newPickedAddons
                    }
                }
                dispatch(dispatchCartData(cartData));
            }else if((pickedAddons.filter(({id}) => id == addonToBeAdd.id)).length > 0){
                const newPickedAddons = pickedAddons.filter(({id}) => id != addonToBeAdd.id);
                cartData = {
                    ...cartData,
                    purchase_addon_cart: {
                        pickedAddons: newPickedAddons
                    }
                }
                dispatch(dispatchCartData(cartData));
            }else{
                try {
                    if((pickedAddons.filter(({id}) => id == addonToBeAdd.one_time_id || id == addonToBeAdd.id)).length === 0) {
                        pickedAddons.push(
                            addonToBeAdd
                        );
                        cartData = {
                            ...cartData,
                            purchase_addon_cart: {
                                pickedAddons: pickedAddons
                            }
                        }
                        dispatch(dispatchCartData(cartData));
                    }
                }catch(ex){
        
                }
            }
        }
    }

    const formatAmount = (amount) => {
        const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        return priceSplitter(parseFloat(Math.round(amount * 100) / 100).toFixed(2))
    }

    
    return (
        <React.Fragment>
            <Header />
            <section id="__choose-plan">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="plan-picklist">
                                <div className="title">
                                    <h4>Choose Below</h4>
                                </div>
                                <div className="question">
                                    <h4>Monthly Subscriptions</h4>
                                </div>
                                {
                                    (addons || []).map((data, index) => (
                                        data.available_plan_types.includes(themeData.plan_type) && data.is_one_payment == 0 && data.one_time_id != null && data.product_type_ids.includes(themeData.product.product_type_id) && !addon_feature_ids.includes(data.id) ?
                                        <div className={"product "+(data.addon_code == "boss_app" ? "long" : "")}>
                                            {/* row starts here for inner grid (item and add cart rows)*/}
                                                <div className="product-details">
                                                    <div className="product-name">
                                                        <h4 id={`${data.addon_code}-description`}>{data.name} | {userData.currency_sym}{formatAmount(data.price)}/month</h4>
                                                    </div>
                                                    <div className="product-description">
                                                        {/* <p>Ut enim ad minim veniam, quis nostrud exercitation.</p> */}
                                                    </div>
                                                    {
                                                        data.one_time_id != null ? 
                                                        <div className="mt-2">
                                                            <Form>
                                                                <div key={`inline-${data.addon_code}`}>
                                                                    <Form.Check 
                                                                        inline 
                                                                        label="Monthly" 
                                                                        type={"radio"} 
                                                                        id={`inline-${data.addon_code}-monthly`} 
                                                                        onChange={() => {handleAddonType(`inline-${data.addon_code}-monthly`, data)}} 
                                                                        disabled={pickedAddons.filter(({id}) => id == data.one_time_id).length == 1 || pickedAddons.filter(({id}) => id == data.id).length == 1 ? true : false}
                                                                    />
                                                                    <Form.Check 
                                                                        inline 
                                                                        label="One-Time Setup" 
                                                                        type={"radio"} 
                                                                        id={`inline-${data.addon_code}-one-time`} 
                                                                        onChange={() => {handleAddonType(`inline-${data.addon_code}-one-time`, data)}} 
                                                                        disabled={pickedAddons.filter(({id}) => id == data.one_time_id).length == 1 || pickedAddons.filter(({id}) => id == data.id).length == 1 ? true : false}
                                                                    />
                                                                </div>
                                                            </Form>
                                                        </div>
                                                        : 
                                                        null
                                                    }
                                                </div>
                                                <div className="product-action high">
                                                    {
                                                        data.addon_code == "boss_app" && (pickedAddons.filter(({id}) => id == data.one_time_id || id == data.id).length == 0) ? 
                                                        <div className="quantity text-md-center">
                                                            <div className="take-away" onClick={() => {
                                                                if(bossAppQty > 1){
                                                                    setBossAppQty(pevQty => pevQty-1)
                                                                }
                                                            }}>
                                                                <p>-</p>
                                                            </div>
                                                            <div className="total-quantity">
                                                                <input type="number" value={bossAppQty} onChange={(e) => {
                                                                    setBossAppQty(parseInt(e.target.value))
                                                                }}
                                                                />
                                                            </div>
                                                            <div className="take" onClick={() => {
                                                                setBossAppQty(pevQty => pevQty+1)
                                                            }}>
                                                                <p>+</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    <a href="#add-to-cart" className={pickedAddons.filter(({id}) => id == data.one_time_id || id == data.id).length == 1 ? "remove" : ""} onClick={(e) => {e.preventDefault(); handleAddAddons(data)}}><u>{pickedAddons.filter(({id}) => id == data.one_time_id || id == data.id).length == 1 ? "REMOVE" : "ADD TO CART"}</u></a>
                                               
                                                </div>
                                            
                                        </div>
                                        : 
                                        null
                                    ))
                                }
                                {
                                    (addons || []).map((data, index) => (
                                        data.available_plan_types.includes(themeData.plan_type) && data.is_one_payment == 0 && data.one_time_id == null && data.product_type_ids.includes(themeData.product.product_type_id) && !addon_feature_ids.includes(data.id) ?
                                        <div className="product">
                                            {/* row starts here for inner grid (item and add cart rows)*/}
                                                <div className="product-details">
                                                    <div className="product-name">
                                                        <h4 id={`${data.addon_code}-description`}>{data.name} | {userData.currency_sym}{formatAmount(data.price)}/month</h4>
                                                    </div>
                                                    <div className="product-description">
                                                        {/* <p>Ut enim ad minim veniam, quis nostrud exercitation.</p> */}
                                                    </div>
                                                </div>
                                                <div className="product-action">
                                                    <a href="#add-to-cart" className={pickedAddons.filter(({id}) => id == data.id).length == 1 ? "remove" : ""} onClick={(e) => {e.preventDefault(); handleAddAddons(data)}}><u>{pickedAddons.filter(({id}) => id == data.id).length == 1 ? "REMOVE" : "ADD TO CART"}</u></a>
                                                </div>
                                        </div>
                                        : 
                                        null
                                    ))
                                }
                                
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="order-summary">
                                <div className="order-summary-content">
                                    <div className="title text-center">
                                        <h4>Order Summary</h4>
                                    </div>
                                    <div className="cart">
                                        <div className="cart-item">
                                            <div>
                                                <label>Additional Features</label>
                                            </div>
                                            {
                                                (pickedAddons || []).map((item, index) => (
                                                    <div className="item-details">
                                                        {
                                                            item.addon_code == "boss_app" ? 
                                                                <React.Fragment>
                                                                    <p className="item-name">{item.name} x {item.quantity}</p><p className="item-price">{item.price != '' ? userData.currency_sym : null}{formatAmount(item.price * item.quantity)}</p>
                                                                </React.Fragment>
                                                            :
                                                                <React.Fragment>
                                                                    <p className="item-name">{item.name}</p><p className="item-price">{item.price != '' ? userData.currency_sym : null}{formatAmount(item.price)}</p>
                                                                </React.Fragment>
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <hr/>
                                        <div className="cart-action">
                                            <button className="btn btn-primary" onClick={() => {
                                                if(pickedAddons.length > 0){
                                                    executeUpdgrade();
                                                }else{
                                                    alert('Please select an add-on!');
                                                }
                                                
                                            }}>
                                                PLACE ORDER
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="__action-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 ">
                            <div className="">
                                <button className="btn btn-muted float-left" onClick={(e) => {
                                e.preventDefault();
                                window.history.back();
                            }}>BACK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
}

export default PurchaseAddons
