import React, { useEffect, useState } from 'react'
import HeaderLogo from '../../assets/my-store-logo-1.png'
import FooterLogo from '../../assets/prosperna-footer-logo.png'
import axios from 'axios'
import { Redirect, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { updateUser, getAuthorizationToken } from '../../redux/actions/UserActions';
import { Spinner } from 'react-bootstrap';

function VerifyEmail() {
    const baseURL = process.env.REACT_APP_API_URL;
    const userData = useSelector(state => state.user.user_details);
    const [isLoading, setIsLoading] = useState(false);
    const [isVerifyingCode, setVrifyingCode] = useState(false);
    const [code, setCode] = useState("");
    const [inputCode, setInputCode] = useState("");
    const [goToDashboard, setGoToDashboard] = useState(false);
    const authToken = getAuthorizationToken( userData.session_token, userData.user_id);

    const updateEmailVerificationStatus = async (email_verified) => {
        setVrifyingCode(true);
        userData.email_verified = email_verified;
        const updatedUserResponse = await Promise.resolve(updateUser(userData, authToken));
        setVrifyingCode(false);
        setGoToDashboard(true);
    }
    const sendCode = () => {
        setIsLoading(true)
        return axios
        .get(baseURL+`/send-email-verification-code/${userData.user_id}`,
            {
                headers: { 'Content-Type': 'application/json' }
            }
        )
        .then(function(response) {
            // console.log('response.data',response.data)
            setCode(response.data.code);
            alert("Email verification sent.")
            setIsLoading(false);
        })
        .catch(error => {
            alert(error);
            setIsLoading(false);
        })
    }
    const handleResendCode = (e) => {
        e.preventDefault();
        if(!isLoading){
            sendCode();
        }
    }
    const handleInputCodeChange = (e) => {
        setInputCode(e.target.value)
    }
    const handleCodeValidation = () => {
        if(!isVerifyingCode || !isLoading){
            if(code === inputCode){
                updateEmailVerificationStatus(1);
            }else{
                if(inputCode === ""){
                    alert("Please enter your verification code.")
                }else{
                    alert("Invalid verification code.")
                }
            }
        }
    }
    useEffect(() => {
        document.title = "Verify Email | MyStore"
        sendCode();
    }, [])

    const encryptEmail = (email) => {
        let name = email.substring(0,email.indexOf("@"));
        let x = ""
        for (var i = 0; i < name.length; i++) {
            x = x + "x"
        }
        return  x + '@' + email.substring(email.lastIndexOf("@") +1);
    }

    if(goToDashboard) {
        return <Redirect to={{
            pathname: '/dashboard'
        }}/>
    }
    return (
        <section id="__request-finished">
            <div className="container">
                <div className="row __header">
                    <img src={HeaderLogo} alt="my-store-logo-1"/>
                </div>
                <div className="row __body">
                    <div className="__message-wrap">
                        <h1>Verify email</h1>
                        <p>We sent an email to {encryptEmail(userData.email_address)} to make sure you own it. Please check your inbox and enter <br/> the security code below to finish setting up your myStore account.</p>
                    </div>
                </div>
                <div className="row __input-field mb-5">
                    <div className="col-lg-12 text-center">
                        <div className="mb-3">
                            <input type="text" placeholder={isLoading ? "Sending Verification Code" : "Enter Code"} onChange={handleInputCodeChange.bind(this)} disabled={isLoading}/>
                        </div>
                        <div>
                            <label className="text-muted text-center">Didn't receive the email? <a href="#resend" onClick={handleResendCode.bind(this)} disabled={isLoading}>Resend it now</a></label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="__button-wrap">
                        <button className="btn btn-primary" onClick={() => {handleCodeValidation()}}>{isLoading || isVerifyingCode ? <Spinner animation="border"></Spinner> : "NEXT"}</button>
                    </div>
                </div>
                <div className="row __footer">
                    <p>Powered by <img src={FooterLogo} alt="prosperna-footer-logo"/></p>
                </div>
            </div>
        </section>
    )
}

export default VerifyEmail
