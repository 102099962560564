import React, { useState,useEffect } from 'react';
import '../../styles/BuildWebsite.scss';
import { Form,Col,InputGroup } from 'react-bootstrap'
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { dispatchCartData, getPlansTypes, getAdditionalServices, getPromoCodes, getAllAddons } from '../../redux/actions/CartActions'
import { getAuthorizationToken } from '../../redux/actions/UserActions';
import { Spinner } from 'react-bootstrap';

function SelectAddons() {
    const dispatch = useDispatch();
    let cartData =  useSelector(state => state.cart.cart);
    let pickedAddons = cartData.pickedAddons;
    const userData = useSelector(state => state.user.user_details);

    const [isFetchingAddons, setFetchingAddons] = useState(true);
    const [addons, setAddons] = useState([]);
    const [isCheckingPromoCode, setCheckingPromoCode] = useState(false);
    const [goToCheckout, setGoToCheckout] = useState(false);
    const [goToThemes, setGoToThemes] = useState(false);
    const [goToAddOns, setGoToAddOns] = useState(false);
    const [bossAppQty, setBossAppQty] = useState(1);
    // const [pickedAddons, setPickedAddons] = useState([]);

    useEffect(async () => {
        const authToken = getAuthorizationToken(userData.session_token, userData.user_id); 
        const getAllAddonsResponse = await Promise.resolve(getAllAddons(userData.user_id, authToken));
        const {success, fetched_data} = getAllAddonsResponse;
        if(success){
            setAddons(fetched_data);
        }else{
            alert(fetched_data);
        }
        setFetchingAddons(false);
        console.log('setFetchingAddons',addons);
        for(let count = 0; count < pickedAddons.length; count++){
            const {addon_code, one_time_id, is_one_payment, name, price} = pickedAddons[count];
            if(document.getElementById(`inline-${addon_code}-monthly`) != null){
                if(is_one_payment == 0){
                    document.getElementById(`${addon_code}-description`).innerText = `${name} | ${userData.currency_sym}${formatAmount(price)}/month`;
                    document.getElementById(`inline-${addon_code}-monthly`).checked = true;
                    document.getElementById(`inline-${addon_code}-one-time`).checked = false;
                }
                
                if(is_one_payment == 1){
                    if(one_time_id != null){
                        const one_time_data = addons.filter(({id}) => 
                            one_time_id == id
                        )[0]
                        document.getElementById(`${addon_code}-description`).innerText = `${one_time_data.name} | ${userData.currency_sym}${formatAmount(one_time_data.price)}`;
                    }
                    document.getElementById(`inline-${addon_code}-monthly`).checked = false;
                    document.getElementById(`inline-${addon_code}-one-time`).checked = true;
                }
            }
        }
    }, [])

    const handleAddonType = (id, data) => {
        const {addon_code, one_time_id} = data;
        // alert(document.getElementById(`inline-${addon_code}-monthly`).checked);
        if(`inline-${addon_code}-monthly` == id){
            document.getElementById(`${addon_code}-description`).innerText = `${data.name} | ${userData.currency_sym}${formatAmount(data.price)}/month`;
            document.getElementById(`inline-${addon_code}-monthly`).checked = true;
            document.getElementById(`inline-${addon_code}-one-time`).checked = false;
        }
        
        if(`inline-${addon_code}-one-time` == id){
            if(one_time_id != null){
                const one_time_data = addons.filter(({id}) => 
                    one_time_id == id
                )[0]
                document.getElementById(`${addon_code}-description`).innerText = `${one_time_data.name} | ${userData.currency_sym}${formatAmount(one_time_data.price)}`;
            }
            document.getElementById(`inline-${addon_code}-monthly`).checked = false;
            document.getElementById(`inline-${addon_code}-one-time`).checked = true;
        }
    }

    const handleAddAddons = (selectedAddon) => {
        const {addon_code} = selectedAddon;
        let addonToBeAdd = null;
        if(document.getElementById(`inline-${addon_code}-monthly`) == null){
            addonToBeAdd = selectedAddon;
        }else{
            if(document.getElementById(`inline-${addon_code}-monthly`).checked){
                //selected addon is always monthly
                addonToBeAdd = selectedAddon;
            }else if(document.getElementById(`inline-${addon_code}-one-time`).checked){
                addonToBeAdd = addons.filter(({id}) => 
                    selectedAddon.one_time_id == id
                )[0]
                
            }else{
                alert("Please select add-on type");
            }
        }
        if(addonToBeAdd != null){
            if(addonToBeAdd.addon_code == "boss_app"){
                addonToBeAdd.quantity = bossAppQty;
            }else{
                addonToBeAdd.quantity = 1;
            }

            if((pickedAddons.filter(({id}) => id == addonToBeAdd.one_time_id)).length > 0){
                const newPickedAddons = pickedAddons.filter(({id}) => id != addonToBeAdd.one_time_id);
                cartData = {
                    ...cartData,
                    pickedAddons: newPickedAddons
                }
                dispatch(dispatchCartData(cartData));
            }else if((pickedAddons.filter(({id}) => id == addonToBeAdd.id)).length > 0){
                const newPickedAddons = pickedAddons.filter(({id}) => id != addonToBeAdd.id);
                cartData = {
                    ...cartData,
                    pickedAddons: newPickedAddons
                }
                dispatch(dispatchCartData(cartData));
            }else{
                try {
                    if((pickedAddons.filter(({id}) => id == addonToBeAdd.one_time_id || id == addonToBeAdd.id)).length === 0) {
                        pickedAddons.push(
                            addonToBeAdd
                        );
                        cartData = {
                            ...cartData,
                            pickedAddons: pickedAddons
                        }
                        dispatch(dispatchCartData(cartData));
                    }
                }catch(ex){
        
                }
            }
        }
    }

    const formatAmount = (amount) => {
        const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        return priceSplitter(parseFloat(Math.round(amount * 100) / 100).toFixed(2))
    }

    const checkPromoCode = async () => {
        setCheckingPromoCode(true);
        const { value } = document.getElementById('promo_code_field');
        if(value == ""){
            alert("Please enter a promo code!");
            setCheckingPromoCode(false);
        }else{
            const authToken = getAuthorizationToken(userData.session_token, userData.user_id);
            const promoCodeResponse = await Promise.resolve(getPromoCodes(userData.user_id, authToken));
            const {success, fetched_data} = promoCodeResponse;
            let validated = false;
            if(success){
                for(let count = 0; count < fetched_data.promo_codes.length; count++){
                    if(fetched_data.promo_codes[count].promo_code === value && fetched_data.promo_codes[count].currency == (userData.currency == "1" ? "USD" : "PHP")){
                        cartData = {
                            ...cartData,
                            promo_code: fetched_data.promo_codes[count],
                        }
                        dispatch(dispatchCartData(cartData));
                        validated = true;
                        break;
                    }
                    else if(fetched_data.promo_codes[count].promo_code === value && (fetched_data.promo_codes[count].currency == null || fetched_data.promo_codes[count].currency == "")){
                        cartData = {
                            ...cartData,
                            promo_code: fetched_data.promo_codes[count],
                        }
                        dispatch(dispatchCartData(cartData));
                        validated = true;
                        break;
                    }
                }
            }else{
                alert(fetched_data);
            }

            if(!validated){
                alert('Promo code invalid!');
            }

            setCheckingPromoCode(false);

            console.log(promoCodeResponse);
        }
    }

    if(goToCheckout){
        return <Redirect to='/build-website/checkout' />
    }else if(goToThemes){
        return <Redirect to={{
            pathname: '/build-website/plans',
        }}/>
    }else if(goToAddOns){
        return <Redirect to={{
            pathname: '/build-website/add-ons',
        }}/>
    }
    return (
        isFetchingAddons ?
            <React.Fragment>
                <br/><br/><br/>
                <center ><p><Spinner animation="border"></Spinner></p> </center>
            </React.Fragment>
        :
        <React.Fragment>
            <section id="__choose-plan">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="plan-picklist">
                                <div className="title">
                                    <h4>Choose Below</h4>
                                </div>
                                <div className="question">
                                    <h4>Monthly Subscriptions</h4>
                                </div>
                                {
                                    (addons || []).map((data, index) => (
                                        data.available_plan_types.includes(cartData.plan_type) && data.is_one_payment == 0 && data.one_time_id != null && data.product_type_ids.includes(cartData.theme_type_id) ?
                                        <div className={"product "+(data.addon_code == "boss_app" ? "long" : "")}>
                                            <div className="product-details">
                                                <div className="product-name">
                                                    <h4 id={`${data.addon_code}-description`}>{data.name} | {userData.currency_sym}{formatAmount(data.price)}/month</h4>
                                                </div>
                                                <div className="product-description">
                                                    {/* <p>Ut enim ad minim veniam, quis nostrud exercitation.</p> */}
                                                </div>
                                                {
                                                    data.one_time_id != null ? 
                                                    <div className="mt-2">
                                                        <Form>
                                                            <div key={`inline-${data.addon_code}`}>
                                                                <Form.Check 
                                                                    inline 
                                                                    label="Monthly" 
                                                                    type={"radio"} 
                                                                    id={`inline-${data.addon_code}-monthly`} 
                                                                    onChange={() => {handleAddonType(`inline-${data.addon_code}-monthly`, data)}} 
                                                                    disabled={pickedAddons.filter(({id}) => id == data.one_time_id).length == 1 || pickedAddons.filter(({id}) => id == data.id).length == 1 ? true : false}
                                                                />
                                                                <Form.Check 
                                                                    inline 
                                                                    label="One-Time Setup" 
                                                                    type={"radio"} 
                                                                    id={`inline-${data.addon_code}-one-time`} 
                                                                    onChange={() => {handleAddonType(`inline-${data.addon_code}-one-time`, data)}} 
                                                                    disabled={pickedAddons.filter(({id}) => id == data.one_time_id).length == 1 || pickedAddons.filter(({id}) => id == data.id).length == 1 ? true : false}
                                                                />
                                                            </div>
                                                        </Form>
                                                    </div>
                                                    : 
                                                    null
                                                }
                                            </div>
                                            <div className="product-action high">
                                                {
                                                    data.addon_code == "boss_app" && (pickedAddons.filter(({id}) => id == data.one_time_id || id == data.id).length == 0) ? 
                                                    <div className="quantity">
                                                        <div className="take-away" onClick={() => {
                                                            if(bossAppQty > 1){
                                                                setBossAppQty(pevQty => pevQty-1)
                                                            }
                                                        }}>
                                                            <p>-</p>
                                                        </div>
                                                        <div className="total-quantity">
                                                            <input type="number" value={bossAppQty} onChange={(e) => {
                                                                setBossAppQty(parseInt(e.target.value))
                                                            }}
                                                            />
                                                        </div>
                                                        <div className="take" onClick={() => {
                                                            setBossAppQty(pevQty => pevQty+1)
                                                        }}>
                                                            <p>+</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                <center>
                                                <a href="#add-to-cart" className={pickedAddons.filter(({id}) => id == data.one_time_id || id == data.id).length == 1 ? "remove" : ""} onClick={(e) => {e.preventDefault(); handleAddAddons(data)}}><u>{pickedAddons.filter(({id}) => id == data.one_time_id || id == data.id).length == 1 ? "REMOVE" : "ADD TO CART"}</u></a>
                                                </center>
                                            </div>
                                        </div>
                                        : 
                                        null
                                    ))
                                }
                                {
                                    (addons || []).map((data, index) => (
                                        data.available_plan_types.includes(cartData.plan_type) && data.is_one_payment == 0 && data.one_time_id == null && data.product_type_ids.includes(cartData.theme_type_id) ?
                                        <div className="product">
                                            <div className="product-details">
                                                <div className="product-name">
                                                    <h4 id={`${data.addon_code}-description`}>{data.name} | {userData.currency_sym}{formatAmount(data.price)}/month</h4>
                                                </div>
                                                <div className="product-description">
                                                    {/* <p>Ut enim ad minim veniam, quis nostrud exercitation.</p> */}
                                                </div>
                                            </div>
                                            <div className="product-action">
                                                <a href="#add-to-cart" className={pickedAddons.filter(({id}) => id == data.id).length == 1 ? "remove" : ""} onClick={(e) => {e.preventDefault(); handleAddAddons(data)}}><u>{pickedAddons.filter(({id}) => id == data.id).length == 1 ? "REMOVE" : "ADD TO CART"}</u></a>
                                            </div>
                                        </div>
                                        : 
                                        null
                                    ))
                                }
                                
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="order-summary">
                                <div className="promo-code-form">
                                    <Form>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="promo_code_field">
                                                <InputGroup >
                                                    <Form.Control type="text" aria-describedby="inputGroupPrepend" placeholder="PROMO CODE" />
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="inputGroupPrepend" onClick={() => checkPromoCode()} disable={isCheckingPromoCode}>{isCheckingPromoCode ? <Spinner animation="border" size="sm"></Spinner>: "ADD"}</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control.Feedback type="invalid" >
                                                        Please provide a valid Promo code.
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Form.Row>
                                    </Form>
                                </div>
                                <div className="order-summary-content">
                                    <div className="title text-center">
                                        <h4>Order Summary</h4>
                                    </div>
                                    <div className="cart">
                                        <div className="cart-item">
                                            <div>
                                                <label>Theme</label>
                                            </div>
                                            <div className="item-details">
                                                <p className="item-name">{cartData.theme_name}</p><p className="item-price"></p>
                                            </div>
                                        </div>
                                        <div className="cart-item">
                                            <div>
                                                <label>Plan Type</label>
                                            </div>
                                            <div className="item-details">
                                                <p className="item-name">{cartData.plan_type}</p><p className="item-price">{cartData.plan_price != '' ? userData.currency_sym : null}{formatAmount(cartData.plan_price)}</p>
                                            </div>
                                        </div>
                                        <div className="cart-item">
                                            <div>
                                                <label>Additional Services</label>
                                            </div>
                                            <div className="item-details">
                                                <p className="item-name">{cartData.setup_type !== '' ? `${cartData.setup_description.split('|')[0]} Setup` : null} </p><p className="item-price">{cartData.setup_type !== '' ? cartData.setup_description.split('|')[1] : null}</p>
                                            </div>
                                            {
                                                cartData.picked_seo_types.map((item, index) => (
                                                    <div className="item-details">
                                                        <p className="item-name">{item.string_description.split('|')[0]}</p><p className="item-price">{item.string_description.split('|')[1]}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="cart-item">
                                            <div>
                                                <label>Additional Features</label>
                                            </div>
                                            {
                                                pickedAddons.map((item, index) => (
                                                    <div className="item-details">
                                                        {
                                                            item.addon_code == "boss_app" ? 
                                                                <React.Fragment>
                                                                    <p className="item-name">{item.name} x {item.quantity}</p><p className="item-price">{item.price != '' ? userData.currency_sym : null}{formatAmount(item.price * item.quantity)}</p>
                                                                </React.Fragment>
                                                            :
                                                                <React.Fragment>
                                                                    <p className="item-name">{item.name}</p><p className="item-price">{item.price != '' ? userData.currency_sym : null}{formatAmount(item.price)}</p>
                                                                </React.Fragment>
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="cart-item mt-4">
                                            <div className="item-details">
                                                <p className="item-name"><b>Promo Code</b></p><p className="item-price font-weight-normal">{cartData.promo_code.promo_code || null}</p>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="cart-action">
                                            <button className="btn btn-primary" onClick={() => {
                                                if(cartData.plan_type != ""){
                                                    setGoToCheckout(true)
                                                }else{
                                                    alert("Please select a plan!")
                                                }
                                                
                                            }}>
                                                CHECKOUT
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="__action-wrap" className="fixed-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 ">
                            <div className="">
                                <button className="btn btn-muted float-left" onClick={() => {setGoToThemes(true)}}>BACK</button>
                                {/* <button className="btn btn-hovered float-right" onClick={() => {setGoToAddOns(true)}}>CONTINUE</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default SelectAddons
