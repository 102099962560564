import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Footer from '../Dashboard/Footer';
import Header from '../Dashboard/Header';
import { getAuthorizationToken } from '../../redux/actions/UserActions';
import { verifySession, dispatchUserData } from '../../redux/actions/UserActions';
import axios from 'axios';

function EditUserProfile() {
    const baseURL = process.env.REACT_APP_API_URL;
    const dispatch = useDispatch();
    const [goToVerifyEmail, setGoToVerifyEmail] = useState(false);
    const [userData, setUserData] = useState(useSelector(state => state.user.user_details));

    const [isApiProcessing, setApiProcessing] = useState(false);

    const onUserBasicDetailsChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        });
    };

    const onUserAddressDetailsChange = (e) => {
        setUserData({
            ...userData,
            address: {
                ...userData.address,
                [e.target.name]: e.target.value
            }
        });
    };

    const onClickSave = () => {
        setApiProcessing(true);
        // save user basic details
        const authToken = getAuthorizationToken( userData.session_token, userData.user_id);
        return axios
        .post(baseURL+'/api/user/update/'+userData.user_id, 
        {
            user_data: userData
        },{
            headers: {'Content-Type':'application/json', Authorization: authToken}
        })
        .then(response => {
            if(response.data.success){
                const new_user_data = {
                    address: userData.address,
                    card_details: userData.card_details,
                    company: userData.company,
                    currency: userData.currency,
                    currency_sym: userData.currency_sym,
                    email_address: userData.email_address,
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    phone: userData.phone,
                    session_token: userData.session_token,
                    stripe_id: userData.stripe_id,
                    user_id: userData.user_id,
                    website_subcription_count: userData.website_subcription_count,
                    email_verified: userData.email_verified
                }
                dispatch(dispatchUserData(new_user_data));
                alert('Profile Updated Successfully!');
                window.history.back();
            }else{
                let {message} = response.data;
                for (var key in message) {
                    if (message.hasOwnProperty(key)) {
                        alert(message[key]);
                    }
                }
            }
            setApiProcessing(false);
        })
        .catch(response => {
            alert('Server Error! ' + response.message)
            setApiProcessing(false);
        });
    }
    if(goToVerifyEmail){
        return <Redirect to={{
            pathname: '/verify-email'
        }} />
    }
    return (
        <React.Fragment>
            <Header />
            <section id="__home-verify-email">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="__welcome-message-wrap text-left">
                                <h5>Welcome, {`${userData.first_name} ${userData.last_name}`}</h5>
                                <p className="d-inline-block mr-4">Account Profile</p>
                                {/* <a className="d-inline-block" style={{color: "#fb561f", fontWeight: "500"}} href="#go-back" onClick={(e) => {
                                    e.preventDefault();
                                    window.history.back();
                                }}><u></u></a> */}
                            </div>
                        </div>
                        <div className="col-lg-6 text-right">
                        {/* <Link className="btn btn-primary" to="/">Home</Link> */}
                            {
                                userData.email_verified !== 1 ?
                                    <button className="btn btn-secondary" onClick={() => {setGoToVerifyEmail(true);}}>VERIFY YOUR EMAIL</button>
                                :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section id="__profile" className="mt-4">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-lg-12">
                            <div className="title">
                                <h4>Billing Information</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>First Name</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <input disabled={isApiProcessing} type="text" name="first_name" className='form-control' onChange={onUserBasicDetailsChange.bind(this)} defaultValue={userData.first_name}/>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>Last Name</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <input disabled={isApiProcessing} type="text" name="last_name" className='form-control' onChange={onUserBasicDetailsChange.bind(this)} defaultValue={userData.last_name}/>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>Email</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <input disabled={isApiProcessing} type="email" name="email_address" className='form-control' onChange={onUserBasicDetailsChange.bind(this)} defaultValue={userData.email_address}/>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>Phone</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <input disabled={isApiProcessing} type="text" name="phone" className='form-control' onChange={onUserBasicDetailsChange.bind(this)} defaultValue={userData.phone}/>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>Company</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <input disabled={isApiProcessing} type="text" name="company" className='form-control' onChange={onUserBasicDetailsChange.bind(this)} defaultValue={userData.company}/>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>Address Line 1</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <input disabled={isApiProcessing} type="text" name="address_line1" className='form-control' onChange={onUserAddressDetailsChange.bind(this)} defaultValue={userData.address.address_line1}/>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>City</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <input disabled={isApiProcessing} type="text" name="city" className='form-control' onChange={onUserAddressDetailsChange.bind(this)} defaultValue={userData.address.city}/>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>State/Province</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <input disabled={isApiProcessing} type="text" name="state_province" className='form-control' onChange={onUserAddressDetailsChange.bind(this)} defaultValue={userData.address.state_province}/>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>Zip Code</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <input disabled={isApiProcessing} type="text" name="zipcode" className='form-control' onChange={onUserAddressDetailsChange.bind(this)} defaultValue={userData.address.zipcode}/>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>Country</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <input disabled={isApiProcessing} type="text" name="country" className='form-control' onChange={onUserAddressDetailsChange.bind(this)} defaultValue={userData.address.country}/>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-12 text-center">
                            <button disabled={isApiProcessing} className="btn btn-muted" style={{width: "150px", margin: "5px"}} onClick={() => {window.history.back()}}>Cancel</button>
                            <button disabled={isApiProcessing} className="btn btn-primary" style={{width: "150px", margin: "5px"}} onClick={() => {onClickSave()} }>Save</button>
                        </div>
                    </div>
                    {/* <div className="row mt-3 mb-4">
                        <div className="col-lg-12">
                            <div className="title">
                                <h4>Card Information</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="label">
                                <label>Credit Card</label>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="value">
                                <p>#### #### #### {userData.card_details}</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
}

export default EditUserProfile
