import {
    USER_LOGIN
} from '../actions/Types';

const initialState = {
    user_details: "NOT LOGIN" 
}

export default function(state = initialState, {type, payload}){
    switch(type){
        case USER_LOGIN:
            return {
                ...state,
                user_details: payload
            }
        default: 
            return state;
    }
}