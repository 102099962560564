import React, { useState } from 'react';
import HeaderLogo from '../../assets/my-store-logo-1.png';
import FooterLogo from '../../assets/prosperna-footer-logo.png';
import { Link, Redirect, useLocation } from 'react-router-dom';
import axios from 'axios'

function EnterNewPassword() {
    let location = useLocation();
    const baseURL = process.env.REACT_APP_API_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [inputState, setInputState] = useState({
        newPassword: "",
        confirmPassword: ""
    });
    const [resetSuccess, setResetSuccess] = useState(false);

    const handleChangeInputChange = (e) => {
        const { name, value } = e.target;
        setInputState({
            ...inputState,
            [name]: value
        })
    }

    const handleSavePassword = (e) => {
        const { newPassword, confirmPassword } = inputState;
        if(newPassword != ""){
            if(newPassword == confirmPassword){
                setIsLoading(true);
                return axios
                .post(baseURL+'/api/reset-password', 
                {
                    email: location.state.emailAddress,
                    password: newPassword
                },{
                    headers: {'Content-Type':'application/json'}
                })
                .then(function(response) {
                    // console.log('response.data',response.data)
                    setIsLoading(false);
                    // Set go to next page
                    setResetSuccess(true);
                })
                .catch(error => {
                    alert(error);
                    setIsLoading(false);
                })
            }
            else{
                alert("Password does not match.");
            }
        }else{
            alert("Please enter your new password.");
        }
    }

    if(resetSuccess){
        return <Redirect 
            to={{
                pathname: "/forgot-password/success"
            }}
        />
    }
    return (
        <section id="__request-finished">
            <div className="container">
                <div className="row __header">
                    <img src={HeaderLogo} alt="my-store-logo-1"/>
                </div>
                <div className="row __body mb-3">
                    <div className="__message-wrap">
                        <h1>Enter New Password</h1>
                        <p>Please enter and confirm your new password below to access your account.</p>
                    </div>
                </div>
                <div className="row __input-field mb-3">
                    <div className="col-lg-12 text-center">
                        <div className="mb-3">
                            <input type="password" name="newPassword" placeholder="New Password" onChange={handleChangeInputChange.bind(this)} defaultValue={inputState.newPassword}/>
                        </div>
                        <div className="mb-3">
                            <input type="password" name="confirmPassword" placeholder="Confirm Password" onChange={handleChangeInputChange.bind(this)} defaultValue={inputState.confirmPassword}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="__button-wrap">
                            <button className="btn btn-primary" onClick={handleSavePassword.bind(this)} >SUBMIT</button>
                        </div>
                    </div>
                </div>
                <div className="row __footer">
                    <p>Powered by <img src={FooterLogo} alt="prosperna-footer-logo"/></p>
                </div>
            </div>
        </section>
    )
}

export default EnterNewPassword
