import React, { useState } from 'react';
import HeaderLogo from '../../assets/my-store-logo-1.png';
import FooterLogo from '../../assets/prosperna-footer-logo.png';
import { Redirect } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import axios from 'axios'

function EnterEmailAddress() {
    const baseURL = process.env.REACT_APP_API_URL;
    const [emailAddress, setEmailAddress] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [verificationCode, setVerificationCode] = useState(false);
    const [isGoNextPage, setGoNextPage] = useState(false);
    const handleInputChange = (e) => {
        setEmailAddress(e.target.value)
    }

    const sendCode = () => {
        setIsLoading(true)
        return axios
        .get(baseURL+`/send-email-verification-code-forgot-pass/${emailAddress}`,
            {
                headers: { 'Content-Type': 'application/json' }
            }
        )
        .then(function(response) {
            // console.log('response.data',response.data)
            setIsLoading(false);
            // Set go to next page 
            if(response.data.code === false){
                alert("Email not found!");
            }else{
                setVerificationCode(response.data.code);
                alert("Email verification sent.")
                setGoNextPage(true);
            }
        })
        .catch(error => {
            alert(error);
            setIsLoading(false);
        })
    }
    
    const handleSendCode = (e) => {
        e.preventDefault();
        setIsLoading(true);
        // Call send verification code API
        sendCode();
    }

    if(isGoNextPage){
        return <Redirect 
            to={{
                pathname: "/forgot-password/enter-verification-code",
                state: {
                    emailAddress: emailAddress,
                    verificationCode: verificationCode
                }
            }}
        />
    }
    return (
        <section id="__request-finished">
            <div className="container">
                <div className="row __header">
                    <img src={HeaderLogo} alt="my-store-logo-1"/>
                </div>
                <div className="row __body mb-3">
                    <div className="__message-wrap">
                        <h1>Forgot Your Password?</h1>
                        <p>Enter your email address associated with this account <br/>and we will send you a verification code to reset your password.</p>
                    </div>
                </div>
                <div className="row __input-field mb-3">
                    <div className="col-lg-12 text-center">
                        <div className="mb-3">
                            <input 
                                type="text" 
                                placeholder="Email Address" 
                                defaultValue={emailAddress}
                                onChange = {handleInputChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="__button-wrap">
                       <button onClick={handleSendCode.bind(this)} className="btn btn-primary" disabled={isLoading}>{isLoading ? <Spinner animation="border" size="md" ></Spinner> : "SEND CODE"}</button>
                    </div>
                </div>
                <div className="row __footer">
                    <p>Powered by <img src={FooterLogo} alt="prosperna-footer-logo"/></p>
                </div>
            </div>
        </section>
    )
}

export default EnterEmailAddress
