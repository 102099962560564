import React, { useEffect } from 'react'
import Logo from '../../assets/my-store-logo-1.png'
import FooterLogo from '../../assets/prosperna-footer-logo.png'
import '../../styles/Login.scss'
import LoginForm from './LoginForm'
import webBackdrop from '../../assets/mystore-backdrop-image.png'
import mobileBackdrop from '../../assets/mystore-mobile-bg.png'

function LoginBody() {
    useEffect(() => {
        document.title = "Login | MyStore"
    }, [])
    return (
        <section id="__login">
            <div className="container-fluid">
                <div className="row vh-100">

                    <div className="col-lg-6 __left-banner-mobile" style={{ backgroundImage: `url(${mobileBackdrop})` }}>
                        <div className="__left-banner-content-wrap-mobile">
                            <div className="__logo-wrap-mobile">
                                <img src={Logo} alt="my-store-logo"/>
                            </div>
                            <div className="__message-wrap-mobile">
                                <h1>Log in to myStore</h1>
                                <p>Please enter the required credentials to continue.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 __left-banner" style={{ backgroundImage: `url(${webBackdrop})` }}>
                        <div className="__left-banner-content-wrap">
                            <div className="__logo-wrap">
                                <img src={Logo} alt="my-store-logo"/>
                            </div>
                            <div className="__message-wrap">
                                <h1>Log in to myStore</h1>
                                <p>Please enter the required credentials to continue.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 __right-banner">
                        <LoginForm />
                    </div>
                    <div className="col-12 __right-banner-footer-wrap">
                        <p>Powered by <a href="https://www.prosperna.com/" target="_blank"><img src={FooterLogo} alt="prosperna-footer-logo"/></a></p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LoginBody
