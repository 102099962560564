import React, { useState } from 'react'
import { Form, Col, Button, InputGroup, Spinner } from 'react-bootstrap';
import InfoLogo from '../../assets/info.png'
import { Redirect, useHistory } from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import axios from 'axios';
import emailIcon from '../../assets/email-icon.png';
import lockIcon from '../../assets/lock-icon.png';
import phoneIcon from '../../assets/phone-icon.png';
import userIcon from '../../assets/user-icon.png';
import successCheck from '../../assets/success-check.png'
import failedCheck from '../../assets/failed-check.png'

function SignUpForm() {
    const baseURL = process.env.REACT_APP_API_URL;
    let history = useHistory();
    const [validated, setValidated] = useState(false);
    const [goToLogin, setGoToLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userData, updateUserData] = useState({
        first_name: "",
        last_name: "",
        email_address: "",
        cell_phone: "",
        password: "",
        confirm_password: ""
    });
    const [isShowPass, setIsShowPass] = useState(false);
    const [isShowPassConfirm, setIsShowPassConfirm] = useState(false);
    const [isPassStrength, setPassStrength] = useState(false);
    const [passCharValidation, setPassCharValidation] = useState(false);
    const [passCharCapsValidation, setPassCharCapsValidation] = useState(false);
    const [passCharSymbolValidation, setPassCharSymbolValidation] = useState(false);



 
    const handleCreateUser = () => {
        setIsLoading(true)
        return axios
        .post(
            baseURL+'/api/user',
            {
                email_address: userData.email_address,
                password: userData.password,
                first_name: userData.first_name,
                last_name: userData.last_name,
                phone: userData.cell_phone,
                country: '',
                job_position: ''
            },
            {
                headers: { 'Content-Type': 'application/json' }
            }
        )
        .then(function(response) {
            if(response.data.message === "Created"){
                window.location.replace('/thank-you-sign-up');
                // setGoToLogin(true);
            }
        })
        .catch(error => {
            alert(error.response.data.message);
            setIsLoading(false)
        })
    }

    const handleInputOnChange = (e) => {
        // Password input validation
        if (e.target.name === 'password') {
            if (e.target.value !== '') {
                setPassStrength(true)
            } else {
                setPassStrength(false)
            }

            let passVal = e.target.value
            /**Password length validation */
            if (passVal.length <= 7) {
                setPassCharValidation(false)
            } else {
                setPassCharValidation(true)
            }

            if (passVal.length >= 20) {
                setPassCharValidation(false)
            }
            /**Password length validation */

            /**Password special char checker */
            if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(passVal)) {
                setPassCharSymbolValidation(true)
            } else {
                setPassCharSymbolValidation(false)
            }

            /**Password checking if there's capital letter in the string */
            if (/[`A-Z`]/.test(passVal)) {
                setPassCharCapsValidation(true)
            } else {
                setPassCharCapsValidation(false)
            }
        }

        updateUserData(prevUserData => {
            return {
                ...prevUserData, 
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        }else {
            setValidated(true);
            console.log('userData', userData);
            if(!EmailValidator.validate(userData.email_address)){
                alert('Invalid Email!');
                event.preventDefault();
                event.stopPropagation();
            }else if(userData.password != userData.confirm_password){
                alert('Password not match!');
                event.preventDefault();
                event.stopPropagation();
            }else{
                handleCreateUser()
                history.push("/")
            }
        }
    };

    const handleGoToLogin = (e) => {
        e.preventDefault()
        history.push("/")
        setGoToLogin(true)
    }

    if(goToLogin) {
        return <Redirect to='/login' />
    }
    return (
        <div className="__sign-up-form ">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Row controlId="validationCustom01">
                    <img src={userIcon} alt="email icon"/>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        name="first_name"
                        required
                        type="text"
                        defaultValue={userData.first_name}
                        onChange={handleInputOnChange.bind(this)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid First Name
                    </Form.Control.Feedback>
                </Form.Row>
                <Form.Row>
                    <img src={userIcon} alt="email icon"/>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        name="last_name"
                        required
                        type="text"
                        onChange={handleInputOnChange.bind(this)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid Last Name
                    </Form.Control.Feedback>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                        <img src={emailIcon} alt="email icon"/>
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            name="email_address"
                            type="text" 
                            placeholder="Used for account verification" 
                            required 
                            onChange={handleInputOnChange.bind(this)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid Email Address.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                        <img src={phoneIcon} alt="email icon"/>
                        <Form.Label>Mobile Phone Number</Form.Label>
                        <Form.Control 
                            name="cell_phone"
                            type="text" 
                            required 
                            onChange={handleInputOnChange.bind(this)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid Cell Phone #.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                        <img src={lockIcon} alt="email icon"/>
                        <Form.Label>New Password</Form.Label>
                        <InputGroup>
                            <Form.Control 
                                name="password"
                                type={isShowPass ? "text" : "password"}
                                aria-describedby="inputGroupPrepend" 
                                required 
                                onChange={handleInputOnChange.bind(this)}
                            />
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend" className='input-toggle-pass'><span onClick={() => setIsShowPass(!isShowPass)}>{isShowPass ? "Hide" : "Show"}</span></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control.Feedback type="invalid" >
                                Please provide a valid Password.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                    <img src={lockIcon} alt="email icon"/>
                        <Form.Label>Confirm Password</Form.Label>
                        <InputGroup >
                            <Form.Control 
                                name="confirm_password"
                                type={isShowPassConfirm ? "text" : "password"}
                                aria-describedby="inputGroupPrepend" 
                                required 
                                onChange={handleInputOnChange.bind(this)}
                            />
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend" className='input-toggle-pass'><span onClick={() => setIsShowPassConfirm(!isShowPassConfirm)}>{isShowPassConfirm ? "Hide" : "Show"}</span></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control.Feedback type="invalid" >
                                Please provide a valid Password.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Form.Row>
                <div className="__password-validation-wrap container-fluid" style={{display: isPassStrength ? '' : 'none'}}>
                    <Form.Group>
                        <Form.Label>
                            <img src={successCheck} style={{display: passCharValidation ? '' : 'none' }}/>
                            <img src={failedCheck} style={{display: !passCharValidation ? '' : 'none' }}/>
                            Your password must contain between 8 and 20 characters.
                        </Form.Label>
                        <Form.Label>
                            <img src={successCheck} style={{display: passCharCapsValidation ? '' : 'none' }}/>
                            <img src={failedCheck} style={{display: !passCharCapsValidation ? '' : 'none' }}/>
                            Your password must have at least 1 capital letter.
                        </Form.Label>
                        <Form.Label>
                            <img src={successCheck} style={{display: passCharSymbolValidation ? '' : 'none' }}/>
                            <img src={failedCheck} style={{display: !passCharSymbolValidation ? '' : 'none' }}/>
                            <span style={{ marginLeft: '5px'}}>Your password must have at least 1 symbol.</span>
                        </Form.Label>
                    </Form.Group>
                </div>
                <Form.Row className="__submit-btn-wrap justify-content-center d-flex">
                    <Button type="submit"
                    disabled={ passCharValidation && passCharCapsValidation && passCharSymbolValidation ? false : true }
                    >{isLoading ? <Spinner animation="border" /> : 'Continue'}</Button>
                </Form.Row>
                <Form.Row className='__login-container justify-content-center d-flex mt-4'>
                    <label className="text-muted text-center">Already have an account? <a href="#login" onClick={handleGoToLogin.bind(this)}>Login</a></label>
                </Form.Row>
            </Form>
        </div>
    );
}

export default SignUpForm
