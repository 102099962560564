import React, { useEffect, useState } from 'react'
import { Redirect, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Header from '../Dashboard/Header';
import Footer from '../Dashboard/Footer';
import { Dropdown } from 'react-bootstrap';
import ActionIcon from '../../assets/action-icon.png';

function Invoices() {
    let location = useLocation();
    const userData = useSelector(state => state.user.user_details);
    const themeData = useSelector(state => state.theme.theme_data);

    const [goToInvoices, setGoToInvoices] = useState(false);

    useEffect(() => {
        // console.log(themeData.invoices.data);
        if(themeData == undefined){
            alert("No invoice found");
            window.history.back();
        }
    }, [])

    const formatUnixDate = (date) => {
		let unix_timestamp = date;
		// Create a new JavaScript Date object based on the timestamp
		// multiplied by 1000 so that the argument is in milliseconds, not seconds.
		var date = new Date(unix_timestamp * 1000);
		var month = date.getMonth() + 1;
		var day = date.getDate();
		var year = date.getFullYear();
		var formattedTime = month + "/" + day + "/" + year;

		return(formattedTime);
    }
    
    return (
        <React.Fragment>
            <Header />
            <section id="__theme-single-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-left invoice-header">
                            <h1 className="m-0">Invoices</h1>
                            <a href="#go-back" onClick={(e) => {
                                e.preventDefault();
                                window.history.back();
                            }}><u>Go Back to Previous Page</u></a>
                            <br/>
                            <hr className="mt-3 mb-0"/>
                        </div>
                        <div className="col-lg-12">
                            <table style={{width: "100%"}}>
                                <thead>
                                    <tr className="heading-tbl">
                                        <th style={{minWidth: '150px'}}>Invoice #</th>
                                        <th style={{minWidth: '150px'}}>Date</th>
                                        <th style={{minWidth: '150px'}}>Currency</th>
                                        <th style={{minWidth: '150px'}}>Amount</th>
                                        <th style={{minWidth: '150px'}}>Status</th>
                                        <th className="actions-lbl" style={{minWidth: '150px'}}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        themeData.invoices.data.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data.number}</td>
                                                <td>
                                                    {formatUnixDate(data.created)}
                                                </td>
                                                <td>
                                                    {data.currency.toUpperCase()}
                                                </td>
                                                <td>
                                                    {parseFloat(Math.round((data.amount_due / 100) * 100) / 100).toFixed(2)}
                                                </td>
                                                <td>{data.status.charAt(0).toUpperCase() + data.status.slice(1)}</td>
                                                <td>
                                                    <Dropdown className="user-action-wrap">
                                                        <Dropdown.Toggle>
                                                            <img className="action-btn" src={ActionIcon}></img>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href={data.hosted_invoice_url} target="_blank">View</Dropdown.Item>
                                                            <Dropdown.Item href={data.invoice_pdf} target="_blank">Download PDF</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td> 
                                            </tr>
                                        ))
                                    }
                                    
                                </tbody>
                            </table>
                            <br />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
}

export default Invoices
