import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Logo from '../../assets/my-store-logo-1.png'
import FooterLogo from '../../assets/prosperna-footer-logo.png'
import '../../styles/SignUp.scss'
import SignUpForm from './SignUpForm'
import webBackdrop from '../../assets/mystore-backdrop-image.png'
import mobileBackdrop from '../../assets/mystore-mobile-bg.png'


function SignUpBody() {
    // dc = 'DEMO CHECKOUT'
    // let { dc } = useParams();

    useEffect(() => {
        document.title = "Sign Up | MyStore";

        
        if(getUrlVars()["dc"] == "true"){
            localStorage.setItem("loginRedirectURL", "/build-website/industry");
            localStorage.setItem("themeIdToAdd", getUrlVars()["pid"]);
            localStorage.setItem("themeTypeIdToAdd", "2");
        }else{
            localStorage.setItem("loginRedirectURL", null);
            localStorage.setItem("themeIdToAdd", null);
            localStorage.setItem("themeTypeIdToAdd", null);
        }
        
    }, [])

    const getUrlVars = () => {
		let vars = {};
		let parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
			vars[key] = value;
		});
		return vars;
	}

    return (
        <section id="__sign-up">
            <div className="container-fluid">
                <div className="row vh-100">

                    <div className="col-lg-6 __left-banner-mobile" style={{ backgroundImage: `url(${mobileBackdrop})` }}>
                        <div className="__left-banner-content-wrap-mobile">
                            <div className="__logo-wrap-mobile">
                                <img src={Logo} alt="my-store-logo"/>
                            </div>
                            <div className="__message-wrap-mobile">
                                <h1>Create Your Online Store</h1>
                                <p>Start selling online, accepting payments and</p>
                                <p>automatically book shipping without any coding skills.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 __left-banner" style={{ backgroundImage: `url(${webBackdrop})` }}>
                        <div className="__left-banner-content-wrap">
                            <div className="__logo-wrap">
                                <img src={Logo} alt="my-store-logo"/>
                            </div>
                            <div className="__message-wrap">
                                <h1>Create Your Online Store</h1>
                                <p>Start selling online, accepting payments and</p>
                                <p>automatically book shipping without any coding skills.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 __right-banner">
                        <SignUpForm />
                    </div>

                    <div className="col-12 __right-banner-footer-wrap">
                        <p>Powered by <a href="https://www.prosperna.com/" target="_blank"><img src={FooterLogo} alt="prosperna-footer-logo"/></a></p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SignUpBody
