import { createStore, combineReducers } from 'redux';
import CartReducer from './reducers/CartReducer';
import UserReducer from './reducers/UserReducer';
import ThemeSettingsReducer from './reducers/ThemeSettingsReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user', 'cart', 'theme']
}

export const persistedReducer = persistReducer(persistConfig, combineReducers({
    user: UserReducer,
    cart: CartReducer,
    theme: ThemeSettingsReducer
}));

export const store = createStore(
    persistedReducer,
    window.devToolsExtension && window.devToolsExtension()
);

export const persistor = persistStore(store);