import React from 'react'
import HeaderLogo from '../../assets/my-store-logo-1.png';
import FooterLogo from '../../assets/prosperna-footer-logo.png';
import { Link } from 'react-router-dom';

function ChangePasswordSuccess() {
    return (
        <section id="__request-finished">
            <div className="container">
                <div className="row __header">
                    <img src={HeaderLogo} alt="my-store-logo-1"/>
                </div>
                <div className="row __body mb-3">
                    <div className="__message-wrap">
                        <h1>Password Changed!</h1>
                        <p>You can now log in to your myStore account with your new password.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="__button-wrap mb-4">
                            <Link to="/login" className="btn btn-primary">LOG IN</Link>
                        </div>
                    </div>
                </div>
                <div className="row __footer">
                    <p>Powered by <img src={FooterLogo} alt="prosperna-footer-logo"/></p>
                </div>
            </div>
        </section>
    )
}

export default ChangePasswordSuccess
