import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../Dashboard/Footer';
import Header from '../Dashboard/Header';
import isValidDomain from 'is-valid-domain';
import axios from 'axios';
import { getAuthorizationToken } from '../../redux/actions/UserActions';
import { Spinner } from 'react-bootstrap';



function ChangeDomain() {
    const themeData = useSelector(state => state.theme.theme_data);
    const userData = useSelector(state => state.user.user_details);
    const baseURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        console.log('themeData', themeData)
    }, [])

    const [isChangeDomainProcessing, setChangeDomainProcessing] = useState("");
    const [newDomain, setNewDomain] = useState("");
    // const [state, setstate] = useState(initialState);

    const polishDomain = (domain) => {
		let polishedDomain = "";
		polishedDomain = domain.replace(/(^\w+:|^)\/\//, '');
		polishedDomain = polishedDomain.replace("www.", "");
		return polishedDomain;
    }
    
    const handleChangeDomain = () => {
        const authToken = getAuthorizationToken( userData.session_token, userData.user_id);
		if(isValidDomain(newDomain)){
			setChangeDomainProcessing(true);
            return axios
            .post(baseURL+'/api/user/domain/change/'+userData.user_id, 
            {
                domain_id: themeData.domain.id,
                new_domain: polishDomain(newDomain),
            },{
                headers: {'Content-Type':'application/json', Authorization: authToken}
            })
            .then(response => {
                if(response.data.success){
                    setChangeDomainProcessing(false);
                    alert(response.data.message);
                }
                else{
                    setChangeDomainProcessing(false);
                    alert("Error occured during processing your request. Please try again later.");
                }
            })
            .catch(response => {
                setChangeDomainProcessing(false);
                alert("Server error! Please try again later.");
            });
		}
        else{
			alert('Please enter a valid domain!');
		}
    }
    
    return (
        <React.Fragment>
            <Header />
            <section id="__change-domain" className="mt-5">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-lg-12 text-center">
                            <h1>Change Your Domain</h1>
                        </div>
                        <div className="col-lg-12 text-center mt-2">
                            {/* PAGE DESCRIPTION */}
                            <p>Create your own unique address with a domain name for your website.</p>
                        </div>
                    </div>
                    
                    {/* button input starts here */}
                    <div className="row __input-field mb-5 text-center">
                        <div className="col-lg-12">
                            <div className="domain-label">
                                <label>Current Domain</label>
                            </div>
                            <div className="domain-input">
                                <input type="text" className="disabled" value={themeData.domain.current_domain_name} disabled/>
                            </div>
                            <div className="domain-link">
                                <a href="#restore">Restore Prosperna Domain </a>
                            </div>
                            <div className="domain-label">
                                <label>New Domain Name</label>
                            </div>
                            <div className="domain-input">
                                <input type="text" value={newDomain} onChange={(e) => {setNewDomain(e.target.value)}} className="" disabled={isChangeDomainProcessing}/>
                            </div>
                            <div className="domain-link">
                                <a href="https://prospernasites.helpdocs.com/faq-s/changing-a-domain" target="_blank">How to Change Domain?</a>
                            </div>
                            {/* <div>
                                <label className="text-muted text-center">Didn't receive the email? <a href="#resend" onClick={handleResendCode.bind(this)} disabled={isLoading}>Resend it now</a></label>
                            </div> */}
                        </div>
                    </div>
                    {/* button row starts here */}
                    <div className="row">
                        <div className="col-lg">
                            <div className="action-wrap">
                                {
                                    isChangeDomainProcessing ? 
                                    <React.Fragment>
                                        <center>
                                            <Spinner animation="grow" variant="danger" size="lg" />
                                            <Spinner animation="grow" variant="warning" size="lg" />
                                            <Spinner animation="grow" variant="success" size="lg" />
                                        </center>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        {/* "btn-group" groups the two button and gives half oval effect"*/}
                                        <div className="btn-group col-sm-5">
                                            <button className="btn btn-muted col-sm" to="/theme-settings" onClick={() => {
                                                window.history.back();
                                            }}>CANCEL</button>
                                            <button className="btn btn-primary col-sm" onClick={() => {handleChangeDomain()}}>SUBMIT</button>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
}

export default ChangeDomain
